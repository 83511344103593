import React, { Component } from 'react'; //different
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import './App.css';
import Footer from './footer';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance from "./axios-instance";
import { NumericFormat } from 'react-number-format';
import Systems from "./SystemFunction";
import Casino from "./casino";
import Slot from "./slot";
import Fishing from "./fising";
import Sport from "./sport";
import TextSlider from "./textslide";
import Afficiate from "./affiliate";
import Signin from "./signin";
import Register from "./register";
import Loss from "./loss";
import Promotion from "./promotionx";
import { isMobile } from 'react-device-detect';
import Profile from "./profile";
import Statement from "./statement";



import Banner from "./banner";
import MoblieMenu from "./mobilemenu";
import Mainindex from "./mainindex";
import Lowmenu from "./lowmenu";
// import Lotto from "./lotto";
import Listgameall from "./linklistall";
// import GameHit from "./gamehit";
import moment from 'moment';
import md5 from 'md5';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LoopTwoTone } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import NProgress from "nprogress";
var settings = {
    dots: true,
    infinite: true,
    slidesToShow: (window.innerWidth <= 760) ? 1 : 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear"
};
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            listitallgame: [],
            lottolist: [],
            slotlist: [],
            sportlist: [],
            fishinglist: [],
            limit: [],
            tabselectdeposit: 1,
            rewardlist: [],
            recentlist: [],
            activePage: 1,
            ThmeseV: null,
            pagecount: 1,
            trueaccount: null,
            products_page_count: 1,
            isActive: false,
            activeTab: 1,
            resetpass: 0,
            paymentlist: null,
            checkcredit: false,
            refer: 1,
            showmodal: false,
            historylist: [],
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            tblprofile: [],
            userid: null,
            afficiate: [],
            modalx: false,
            cashoutSet: [],
            modal: false,
            linkaff: "",
            point: 0,
            depositamount: 0,
            maxwithdrawstatus: 0,
            useradvice: [],
            afficiatetoday: [],
            afficiateall: [],
            balance: 0,
            counter: 300,
            qrcode: null,
            qrcodecheck: false,
            loginstate: null,
            firstname: null,
            lastname: null,
            activeTab: 1,
            alert: null,
            modalOpen: false,
            handleClose: false,
            proderedeemlist: [],
            token: window.localStorage.getItem("token"),
            type: "",
            prolistx: [],

            prodetail: [],
            username: "",
            tabselect: 0,
            showpassnew: false,
            showpasscf: false,
            showpassold: false,
            amount: 0.00,
            remark: "",
            mainacc: "",
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            rankpoint: 0,
            limit: null,
            name: "",
            password: "",
            oldpassword: "",
            passwordcf: "",
            left: false,
            pathname: "",
            linelink: "",
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            checkboxcookie: false,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            settingwheel: "",
            settingcheckin: "",
            userid: null,
            phoneNumber: "",
            balance: 0,
            mainacc: null,
            paymentgateway: null,
            tabselectsec: 0,
            qrcode: null,
            stateregis: 1,
            checkboxacc: 0,
            products_page_count: 1,
            loginstate: false,
            fullname: null,
            lname: null,
            link: "",
            alert: null,
            depositstate: 0,
            stateforget: 1,
            depositamount: 0,
            stateopterror: "",
            btnisActive: true,
            otpphone: "",
            token: window.localStorage.getItem("token"),
            accnumber: null,
            accnumbertxt: null,
            withdrawamount: 0,
            rankpoint: 0,
            changepassword: false,
            linelink: "",
            errortext: "",
            trueaccount: null,
            trueaccount: null,
            datas: null,
            otpphoneforget: "",
            affpercent: 0,
            counter: 300,
            userrecomend: "",
            historylist: [],
            useradvice: [],
            cashoutlist: [],
            tblnextrank: [],
            tblrankall: [],
            maxwithdrawstatus: 0,
            tblprofile: [],
            prodetail: [],
            oldpassword: "",
            linkaff: "",
            webnamex: "",
            inviteSet: "",
            truegiftlink: "",
            proderedeem: [],
            qrcode: null,
            proderedeemlist: [],
            buttoncliam: false,
            limit: null,
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            cashoutSet: [],
            tblPopupMenu1: [],
            tblPopupMenu2: [],
            tblPopupMenu: null,
            termAndCondition: false,
            errorstatus: false,
        }
    }
    copyCode = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                this.notify("warning", 'CopyCode!!!');
            })
            .catch((err) => {
                // console.log(err.message);
            });

    };

    promotionlist = () => {
        instance.post("/api/v1/getpromotion", {
            System: Systems,
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    prolistx: res.data.message,
                });
            }
        }).catch(async (err) => {
            //   console.log(err);

            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }
    checklogin() {
        var token = localStorage.getItem('auth_token');

        console.log(11111111, token);
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.rechecklogin(Username, token);
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }


    async recheccredit(evt) {

        evt.currentTarget.className += " fa-spin";
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        this.setState({
            checkcredit: true,
            userid: decoded.message.playerid,
            username: decoded.message.playerid,
            firstname: decoded.message.firstname,
            lastname: decoded.message.lastname,
            loginstate: true,
        });
        try {
            // console.log(2);
            await instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'recheccredit-App.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                        checkcredit: false,
                    });
                }
                else if (res.data.status === 401) {
                    //   console.log(res.data);
                    localStorage.clear();
                    await this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            ////////console.log(ex);
        }
    }
    async opencliamcashout(evt, cityName) {
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await instance.post("/api/v1/cliamcashout", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                this.rechecklogin(Username, token);
                await this.notify("success", 'ทำรายการรับเงินเรียบร้อย');
            }
            else if (res.data.status === 201) {
                await this.notify("error", 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน');
                return false;
            }
            else if (res.data.status === 202) {
                await this.notify("error", 'ไม่สามารถทำรายการได้');
                return false;
            }

        }).catch(async (err) => {
            // console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }
    onChangexxxxx = (checked) => {
        console.log(128, checked)
        if (checked == 1) {
            if (this.state.showpassold) {
                this.setState({ showpassold: false });
            }
            else {
                this.setState({ showpassold: true });
            }
        }
        else if (checked == 2) {
            if (this.state.showpassnew) {
                this.setState({ showpassnew: false });
            }
            else {
                this.setState({ showpassnew: true });
            }
        }
        else if (checked == 3) {
            if (this.state.showpasscf) {
                this.setState({ showpasscf: false });
            }
            else {
                this.setState({ showpasscf: true });
            }
        }
    }

    onChangex = event => {
        // console.log("event", event.target.name);


        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            this.setState({ [nam]: val });
        }
    };

    checkurl = async (e, url) => {
        console.log(url);
        window.history.pushState(null, null, url);
        NProgress.start();
        this.setState({
            modalx: false,
            modal: false,
            listitallgame: []
        });
        const pathname = window.location.pathname.split("/")
        this.setState({
            modalx: false,
            modal: false,
        });
        console.log(210, pathname);
        if (pathname.length > 1) {
            NProgress.set(0.4);
            if (pathname[1] == "") {
                console.log(250, pathname[1]);
                this.setState({
                    tabselect: 0,
                });
            }
            else if (pathname[1] == "casino") {
                this.setState({
                    tabselect: 10,
                });
            }
            else if (pathname[1] == "gamehit") {
                this.setState({
                    tabselect: 11,
                });
            }
            else if (pathname[1] == "fishing") {
                this.setState({
                    tabselect: 12,
                });
            }
            else if (pathname[1] == "slot") {
                console.log(101, pathname);
                this.setState({
                    tabselect: 13,
                });
            }
            else if (pathname[1] == "sport") {
                this.setState({
                    tabselect: 14,
                });
            }
            else if (pathname[1] == "loss") {
                this.setState({
                    tabselect: 25,
                });
            }

            else if (pathname[1] == "event") {
                this.setState({
                    tabselect: 16,
                });
            }
            else if (pathname[1] == "lotto") {
                this.setState({
                    tabselect: 17,
                });
            }
            else if (pathname[1] == "withdraw") {
                if (this.state.loginstate) {
                    this.setState({
                        tabselect: 2,
                    });
                }
                else {
                    await this.notify("success", 'คุณยังไม่ได้ทำการล๊อคอิน');
                    window.history.pushState(null, null, "/");
                    this.setState({
                        tabselect: 0,
                    });
                }
            }
            else if (pathname[1] == "deposit") {
                if (this.state.loginstate) {
                    this.setState({
                        tabselect: 1,
                    });
                }
                else {
                    await this.notify("success", 'คุณยังไม่ได้ทำการล๊อคอิน');
                    window.history.pushState(null, null, "/");
                    this.setState({
                        tabselect: 0,
                    });
                }
            }
            else if (pathname[1] == "statement") {
                this.setState({
                    tabselect: 3,
                });
            }
            else if (pathname[1] == "profile") {
                this.setState({
                    tabselect: 8,
                });
            }
            else if (pathname[1] == "register") {
                this.setState({
                    tabselect: 22,
                });
            }
            else if (pathname[1] == "signin") {
                this.setState({
                    tabselect: 23,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/overview") {
                this.setState({
                    tabselect: 4,
                    refer: 1
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/member") {
                this.setState({
                    tabselect: 4,
                    refer: 2
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/income-summary") {
                this.setState({
                    tabselect: 4,
                    refer: 3
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/share-network") {
                this.setState({
                    tabselect: 4,
                    refer: 4
                });
            }
            else if (pathname[1] == "rebate") {
                this.setState({
                    tabselect: 5,
                });
            }
            else if (pathname[1] == "promotion") {
                this.setState({
                    tabselect: 7,
                });
            }
            else if (pathname[1] == "listgameall") {
                await this.getgame(pathname[2], pathname[3]);
                this.setState({
                    tabselect: 15,
                });
            }
        }
        else {
            this.setState({
                tabselect: 0,
            });
        }
        NProgress.done();
        window.scrollTo(0, 0)
    }

    async calllisthistory(userid) {
        await instance.post("/api/v1/userwithdrawlist", {
            userid: userid,
            Cat_ID: 0,
            System: Systems,
            page: 1,
            type: 1
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                //  //console.log(datas.withdrawlist);
                this.setState({
                    historylist: datas.withdrawlist
                });

            }
            else {
                // this.CheckThisGoalError();
            }
        });

    }
    confirmdeposit = async (e, num) => {
        console.log(493, num)
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                if (num === 0) {
                    if (token != null) {
                        Username = decoded.message.playerid;
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            console.log(res.data.tblpaymentgatewayx)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.tblpaymentgatewayx.QRcode,
                                paymentlist: res.data.tblpaymentgatewayx,
                                counter: 300,
                                qrcodecheck: true,
                            });
                            await this.notify("success", 'กรุณาสแกน qrcode ภายใน 5 นาที');
                        });

                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await this.notify("warning", 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ');

            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }

        else if (num === 5) {
            document.getElementById("truemonney").click()
            //deposittruemoneyModal
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    onChange = async (event) => {
        console.log(event);
        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            if (nam === "withdrawamount") {
                if (this.state.maxwithdrawstatus === 1) {
                    this.setState({ [nam]: this.state.balance });
                    await this.notify("warning", 'บังคับถอนยอดทั้งหมด');
                }
                else if (Number(val) <= Number(this.state.balance)) {
                    this.setState({ [nam]: val });
                }
                else {
                    await this.notify("warning", 'ยอดถอนของท่านไม่เพียงพอในการถอน');

                }
            } else {
                this.setState({ [nam]: val });
            }

        }
    };
    submitwithdraw = async (e) => {
        console.log(this.state.withdrawamount, this.state.balance);
        if (parseFloat(this.state.withdrawamount) <= parseFloat(this.state.balance)) {
            if (parseFloat(this.state.withdrawamount) >= 10) {
                var token = localStorage.getItem('auth_token');
                if (token != null) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    await instance.post("/api/v1/userwithdraw", {
                        User_id: Username,
                        System: Systems,
                        amount: this.state.withdrawamount
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        console.log(res.data);
                        if (res.data.status === 200) {
                            await this.notify("success", 'รายการถอนเรียบร้อย');
                            this.checkurl(e, "/statement")

                        }
                        else if (res.data.status === 203) {
                            await this.notify("error", 'ท่านติด Turnover อีก ' + res.data.amountturnover + " บาท");

                        }
                        else if (res.data.status === 207) {
                            await this.notify("error", 'ขั้นต่ำในการถอน ' + res.data.maxwithdraw + " บาท");

                        }
                        else {
                            await this.notify("error", "ไม่สามารถทำรายการถอนได้ โปรดแจ้งพนักงาน");
                        }
                    });
                }
                else {
                    await this.notify("error", 'รายการถอนมีปัญหา โปรดติดต่อพนักงาน');
                }
            }
            else {
                await this.notify("warning", 'ขั้นต่ำในการถอน 50 บาท');
            }
        }
        else {
            await this.notify("warning", 'ยอดถอนของท่านไม่เพียงพอในการถอน');
        }

    }
    resetpassword = async (e, type) => {
        e.preventDefault();
        const { phoneNumber, fname, password, oldpassword, passwordcf } = this.state;
        console.log(type, password, oldpassword, passwordcf);
        var token = localStorage.getItem('auth_token');
        if (password === passwordcf) {
            var Username = null;
            var decoded = null;
            if (type === 1 && token != null) {
                decoded = jwt_decode(token);
                Username = decoded.message.playerid;
                instance.post("/api/v1/resetpassword", {
                    username: Username,
                    oldpass: oldpassword,
                    System: Systems,
                    newpass: password,
                    type: type
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    if (res.data.status === 200) {
                        await this.notify("success", 'เปลี่ยนพาสเวิดเรียบร้อย');
                    }
                    else if (res.data.status === 201) {
                        await this.notify("error", 'คุณใส่รหัสเก่าผิดพลาด');
                    }
                    else if (res.data.status === 203) {
                        await this.notify("error", 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง');
                    }
                    else if (res.data.status === 202) {
                        await this.notify("error", 'คุณใช้พาสเวิดเดิม');
                    }
                });
            }
            else if (type === 2) {
                instance.post("/api/v1/resetpassword", {
                    username: phoneNumber,
                    oldpass: oldpassword,
                    newpass: password,
                    System: Systems,
                    type: type
                }).then(async (res) => {
                    if (res.data.status === 200) {
                        await this.notify("success", 'เปลี่ยนพาสเวิดเรียบร้อย');
                    }
                    else if (res.data.status === 201) {
                        await this.notify("error", 'คุณใส่รหัสเก่าผิดพลาด');
                    }
                    else if (res.data.status === 203) {
                        await this.notify("error", 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง');
                    }
                    else if (res.data.status === 202) {
                        await this.notify("error", 'คุณใช้พาสเวิดเดิม');
                    }
                });
            }
            else if (type === 3) {
                instance.post("/api/v1/resetpassword", {
                    username: phoneNumber,
                    oldpass: oldpassword,
                    newpass: password,
                    System: Systems,
                    type: type
                }).then(async (res) => {
                    if (res.data.status === 200) {
                        await this.notify("success", 'เปลี่ยนพาสเวิดเรียบร้อย');
                    }
                    else if (res.data.status === 201) {
                        await this.notify("error", 'คุณใส่รหัสเก่าผิดพลาด');
                    }
                    else if (res.data.status === 203) {
                        await this.notify("error", 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง');
                    }
                    else if (res.data.status === 202) {
                        await this.notify("error", 'คุณใช้พาสเวิดเดิม');
                    }
                });
            }
        }
        else {
            await this.notify("error", 'คุณใส่พาสเวิดไม่ตรงกัน');
        }
    }
    async opencliaminvite(evt, cityName) {
        console.log(cityName);
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await instance.post("/api/v1/cliaminvite", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                this.rechecklogin(Username, token);
                await this.notify("success", 'ทำรายการรับเงินเรียบร้อย');
            }
            else if (res.data.status === 201) {
                await this.notify("error", 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน');
                return false;
            }
            else if (res.data.status === 202) {
                await this.notify("error", 'ไม่สามารถทำรายการได้');
                return false;
            }

        }).catch(async (err) => {
            // console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }
    handleClick = async (e, price) => {
        console.log(price);
        if (price <= this.state.balance) {
            this.setState({
                withdrawamount: price
            });
        }
        else {
            await this.notify("warning", 'ยอดถอนของท่านไม่เพียงพอในการถอน');
        }
    }
    handleClickreposit = async (e, price) => {
        this.setState({
            depositamount: price
        });
    }
    redeempro = async (e, proid) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        //console.log(Username, proid);
        if (token != null) {
            this.setState({
                username: Username,
            });
            await instance.post("/api/v1/redeempromotion", {
                userid: Username,
                System: Systems,
                Proid: proid,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //console.log(res.data.status)
                if (res.data.status === 200) {
                    this.setState({
                        showmodal: false,
                    })
                }
                else if (res.data.status === 201) {
                    await this.notify("error", 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้');
                    e.preventDefault();
                    return false;
                }
                else if (res.data.status === 204) {
                    await this.notify("error", 'ท่านใช้สิทธวันนี้ครบแล้ว');
                    e.preventDefault();
                    return false;
                }
                else if (res.data.status === 202) {
                    await this.notify("error", 'ท่านใช้โปรมั่นชั่นนี้ไปแล้ว');
                    e.preventDefault();
                    return false;
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }
            });
        }
    }
    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        this.checklogin();
        if (!this.state.loginstate) {
            this.notify("error", 'คุณยังไม่ได้เข้าสู่ระบบ');
            return;
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                playerUsername: this.state.username,
                isMobile: isMobile,
                System: Systems,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined) {
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }

    couponredeem = async (e, index) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            await instance.post("/api/v1/usercoupon", {
                User_id: Username,
                System: Systems,
                couponkey: this.state.couponkey,
                type: 1
            }).then(async (res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    await this.notify("success", 'ดำเนินการใช้คูปองเรียบร้อย');
                }
                else {
                    await this.notify("warning", 'ไม่มี Key นี้ในระบบ');
                }
            });
        }
        else {
            await this.notify("warning", 'คุณไม่ได้ล๊อคอิน');
        }

    }

    copy = async (e, accnumber) => {
        toast.success("คัดลอกเรียบร้อย", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

        navigator.clipboard.writeText(accnumber)
    }
    async callgetthemesv(Systems) {
        await instance.post("/api/v1/getthemesv", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                document.body.setAttribute("data-theme", res.data.ThmeseV)
                this.setState({
                    ThmeseV: res.data.ThmeseV
                });

            }
            else {
                // this.CheckThisGoalError();
            }
        });

    }
    async getgame(typepage, partner) {
        await instance.post("/api/v1/tabgamelist", {
            state: "999",
            System: Systems,
            partner: partner,
            game: typepage,
        }).then((res) => {
            console.log(803, res.data);
            if (res.data.status === 200) {
                this.setState({
                    listitallgame: res.data.message
                });
            }
        });
    }
    async getcallprovidor() {
        await instance.post("/api/v1/tabgamelist", {
            state: "2",
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                this.setState({
                    categorylist: res.data.message
                });
            }
        });

        await instance.post("/api/v1/tabgamelist", {
            state: "3",
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                this.setState({
                    slotlist: res.data.message
                });
            }
        });

        await instance.post("/api/v1/tabgamelist", {
            state: "5",
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                this.setState({
                    sportlist: res.data.message
                });
            }
        });

        await instance.post("/api/v1/tabgamelist", {
            state: "8",
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                this.setState({
                    fishinglist: res.data.message
                });
            }
        });
    }
    async componentDidMount() {
        NProgress.start();
        this.checklogin();
        this.getcallprovidor();
        // await this.callgetthemesv(Systems);
        this.promotionlist();

        NProgress.set(0.4);
        try {
            const pathname = window.location.pathname.split("/")
            // console.log(989999, pathname.length);
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    var min = res.data.tblcatagoryx[0].limitdeposit;
                    var max = res.data.tblcatagoryx[0].maxdeposit;
                    var maxwithdrawstatus = res.data.tblcatagoryx[0].withdrawmax
                    var limit = { min, max }
                    this.setState({
                        maxwithdrawstatus: maxwithdrawstatus,
                        linelink: datas[1].value,
                        webnamex: datas[7].value,
                        System: res.data.tblcatagoryx[0].System,
                        cashoutSet: res.data.tblcashoutsetting[0],
                        inviteSet: res.data.tblinvitedsetting[0],
                        datas: datas,
                        limit: limit,
                    });
                }
                else {
                    // this.CheckThisGoalError();
                }
            });
            //   console.log(455, pathname[1] + "/" + pathname[2])
            await instance.post("/api/v1/gettrue", {
                userid: Username,
                System: Systems,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                if (res.data.status === 200) {
                    console.log(res);
                    this.setState({
                        trueaccount: res.data.message.tbltruewallet[0],
                    });

                }
            }).catch(async (err) => {
            });
            if (pathname.length > 1) {
                console.log(pathname);
                if (pathname[1] == "casino") {
                    this.setState({
                        tabselect: 10,
                    });
                }
                else if (pathname[1] == "gamehit") {

                    this.setState({
                        tabselect: 11,
                    });
                }
                else if (pathname[1] == "fishing") {
                    this.setState({
                        tabselect: 12,
                    });
                }
                else if (pathname[1] == "loss") {
                    this.setState({
                        tabselect: 25,
                    });
                }
                else if (pathname[1] == "slot") {
                    this.setState({
                        tabselect: 13,
                    });
                }
                else if (pathname[1] == "register") {
                    this.setState({
                        tabselect: 22,
                    });
                }
                else if (pathname[1] == "signin") {
                    this.setState({
                        tabselect: 23,
                    });
                }
                else if (pathname[1] == "sport") {
                    this.setState({
                        tabselect: 14,
                    });
                }
                else if (pathname[1] == "event") {
                    this.setState({
                        tabselect: 16,
                    });
                }
                else if (pathname[1] == "lotto") {
                    this.setState({
                        tabselect: 17,
                    });
                }
                else if (pathname[1] == "withdraw") {
                    // console.log(1232323)
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 2,
                        });
                    }
                    else {
                        await this.notify("success", 'คุณยังไม่ได้ทำการล๊อคอิน');
                        window.history.pushState(null, null, "/");
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] == "deposit") {
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 1,
                        });
                    }
                    else {
                        await this.notify("success", 'คุณยังไม่ได้ทำการล๊อคอิน');
                        window.history.pushState(null, null, "/");
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] == "statement") {
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 3,
                        });
                    }
                    else {
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] == "profile") {
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 8,
                        });
                    }
                    else {
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/overview") {
                    this.setState({
                        tabselect: 4,
                        refer: 1
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/member") {
                    console.log(63855555);
                    this.setState({
                        tabselect: 4,
                        refer: 2
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/income-summary") {
                    this.setState({
                        tabselect: 4,
                        refer: 3
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/share-network") {
                    this.setState({
                        tabselect: 4,
                        refer: 4
                    });
                }
                else if (pathname[1] == "rebate") {
                    this.setState({
                        tabselect: 5,
                    });
                }
                else if (pathname[1] == "promotion") {
                    this.setState({
                        tabselect: 7,
                    });
                }
                else if (pathname[1] == "listgameall") {
                    await this.getgame(pathname[2], pathname[3]);
                    this.setState({
                        tabselect: 15,
                    });
                }
            }
            try {
                console.log(1625656556);
                var token = localStorage.getItem('auth_token');
                if (token != null) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    await this.rechecklogin(Username, token);
                    this.setState({
                        userid: decoded.message.playerid,
                        username: decoded.message.playerid,
                        firstname: decoded.message.firstname,
                        lastname: decoded.message.lastname,
                        loginstate: true,
                    });
                }
                else {
                    this.setState({
                        loginstate: false,
                    });
                }


            }
            catch (error) {
                console.log(185222, error);
            }
        }
        catch (error) {
            console.log(185222, error);
        }
        if (token != null) {
            var decoded = jwt_decode(token);
            let Username = decoded.message.playerid;
            instance.post("/api/v1/getqrcode", {
                username: Username,
                System: Systems,
                amount: 50
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //////console.log(res.data.picture)
                console.log(16888, res.data.mainacc)
                this.setState({
                    mainacc: res.data.mainacc,
                    paymentgateway: res.data.tblpaymentgatewayx,
                });
            });
        }
        NProgress.done();
    }


    checktab(e, index) {

        if (index == 2 && this.state.tabselectsec == 1) {

        }
        else {
            console.log(1279, index, this.state.tabselectsec, this.state.qrcode);
            if (index == 2 && this.state.qrcode != null) {
                this.setState({
                    tabselectdeposit: 0,
                    tabselectsec: 1,
                });
            }
            else {
                this.setState({
                    tabselectdeposit: index,
                    tabselectsec: 0,
                });
            }
        }
    }
    openAccout(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className += " -open";
        }
    }
    async checkuseradvice() {
        instance.post("/api/v1/checkuseradvice", {
            User_id: this.state.username,
            page: 1,
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                console.log(res.data.message.useradvicelist);
                this.setState({
                    useradvice: res.data.message.useradvicelist
                });
            }
            else {
                // console.log(res.data);
                // this.CheckThisGoalError();
            }
        });
    }

    openAccoutcan(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className = "x-menu-account-list-sidebar";
        }
    }

    async rechecklogin(Username, token) {

        try {
            console.log(45555555555555555);
            const search = window.location.href;
            const paramx = new URLSearchParams(search);
            // const space = search.split("/")
            const space = window.location.host
            var userhash = md5(Username);
            //  const linkaff = "https://pgline88.com/?register=" + userhash;
            const linkaff = "https://" + space + "/register?reg=" + userhash;
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(res.data);

                if (res.data.status === 200) {
                    this.calllisthistory(Username);
                    localStorage.setItem('auth_token', res.data.token)
                    console.log(1067, res.data.userafftoday, res.data.useraffall);
                    this.setState({
                        rankpic: res.data.tblrank.image,
                        rankname: res.data.tblrank.name,
                        linkaff: linkaff,
                        rankob: res.data.tblrank,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        tblprofile: res.data.message,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        afficiatetoday: res.data.userafftoday,
                        afficiateall: res.data.useraffall,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                console.log(err)
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }
    checkpro = async (e, proid) => {
        console.log(248, proid);
        var Username = "";
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        await instance.post("/api/v1/checkpromotion", {
            userid: Username,
            System: Systems,
            Proid: proid,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            console.log(res.data)
            if (res.data.status === 200) {
                //console.log(res.data);
                if (res.data.type === 0) {
                    this.setState({
                        showmodal: true,
                        prodetail: res.data.message.tblpromotions[0],
                    });
                }
                else if (res.data.type === 1) {
                    this.setState({
                        showmodal: true,
                        proderedeem: res.data.message.tblpromotionredeems,
                    });
                }



            }
            else if (res.data.status === 201) {
                this.setState({
                    showmodal: true,
                    prodetail: [],
                    picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
                });

            }
            else if (res.data.status === 202) {
                await this.notify("success", 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย');
                return false;
            }

        }).catch(async (err) => {
            //   console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }


    validateLogout = (e) => {
        localStorage.removeItem('auth_token', '')
        this.setState({
            userid: null,
            username: null,
            password: null,
            firstname: null,
            lastname: null,
            point: null,
            loginstate: false,
        });
    }
    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            // alert("You are successfully signed in...");
            window.location.reload()
        } else {

            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.reload()
        }
    }
    waitnotify = async () => {
        setTimeout(() => { console.log('World!') }, 5000)
    }
    notify = async (mode, msg) => {
        console.log(mode, msg);
        if (mode == "success") {
            await toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeonClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "warning") {
            await toast.warning(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeonClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "error") {
            await toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeonClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "info") {
            await toast.info(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeonClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        await this.waitnotify();
    }
    async opencliamcashout(evt, cityName) {
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await instance.post("/api/v1/cliamcashout", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                this.rechecklogin(Username, token);
                this.notify("success", 'ทำรายการรับเงินเรียบร้อย');

            }
            else if (res.data.status === 201) {
                await this.notify("error", 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน');
                return false;
            }
            else if (res.data.status === 202) {
                await this.notify("error", 'ไม่สามารถทำรายการได้');
                return false;
            }

        }).catch(async (err) => {
            // console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }
    render() {
        const { pathname, categorylist, fishinglist, sportlist, slotlist, lottolist, listitallgame } = this.state
        let { loginstate, userid, username, point, firstname, lastname, balance, proderedeemlist, linkaff, tblprofile, limit } = this.state
        const { accnumber, paymentgateway, mainacc, trueaccount, tabselect } = this.state
        var limits = limit
        var accnumberx;
        //  loginstate = true;
        console.log(3399, tabselect);
        if (accnumber != null) {
            accnumberx = accnumber[0];
        }
        console.log(1326, slotlist);
        return (
            <>
                <nav id="sidebar"
                    className="mCustomScrollbar _mCS_1 mCS-autoHide mCS_no_scrollbar"
                    style={{ overflow: "visible" }}
                >
                    <div
                        id="mCSB_1"
                        className="mCustomScrollBox mCS-minimal mCSB_vertical mCSB_outside"
                        style={{ maxHeight: "none" }}
                        tabIndex={0}
                    >
                        <div
                            id="mCSB_1_container"
                            className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                            style={{ position: "relative", top: 0, left: 0 }}
                            dir="ltr"
                        >
                            <div className="sidebar-header">
                                <button id="dismiss">
                                    <i className="fal fa-times" />
                                </button>
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.checkurl(e, "/")
                                    }}
                                    className="custom-logo-link"
                                    rel="home"
                                    aria-current="page"
                                >
                                    <img
                                        width={365}
                                        height={138}
                                        src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                                        className="custom-logo mCS_img_loaded"
                                        alt="SACASINO"
                                        decoding="async"
                                        fetchpriority="high"
                                        srcSet="/build/web/igame-index-lobby-wm/img/logo.png?v=1 365w, /build/web/igame-index-lobby-wm/img/logo.png?v=1 300w"
                                        sizes="(max-width: 365px) 100vw, 365px"
                                    />
                                </a>{" "}
                            </div>
                            <div className="menu-menu-container">
                                <ul id="menu-menu" className="menu">
                                    <li
                                        id="menu-item-58"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-58"
                                    >
                                        <a role='button'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.checkurl(e, "/")
                                            }}>หน้าหลัก</a>
                                    </li>
                                    <li
                                        id="menu-item-59"
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-59"
                                    >
                                        <a
                                            role='button'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.checkurl(e, "/promotion")
                                            }}
                                        >
                                            โปรโมชั่น
                                        </a>
                                    </li>
                                    <li
                                        id="menu-item-59"
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-59"
                                    >
                                        <a
                                            role='button'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.checkurl(e, "/slot")
                                            }}
                                        >
                                            เกมส์ทั้งหมด
                                        </a>
                                    </li>
                                    <li
                                        id="menu-item-60"
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60"
                                    >
                                        <a role='button'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.checkurl(e, "/deposit")
                                            }}>
                                            ฝาก
                                        </a>
                                    </li>
                                    <li
                                        id="menu-item-61"
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-61"
                                    >
                                        <a role='button'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.checkurl(e, "/withdraw")
                                            }}>
                                            ถอน
                                        </a>
                                    </li>
                                    <li
                                        id="menu-item-64"
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-64"
                                    >
                                        <a href="/logout">
                                            ออกจากระบบ
                                        </a>
                                    </li>
                                    <li
                                        id="menu-item-64"
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-64"
                                    >
                                        <a href={this.state.linelink}>
                                            ติดต่อเรา
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        id="mCSB_1_scrollbar_vertical"
                        className="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal mCSB_scrollTools_vertical"
                        style={{ display: "none" }}
                    >
                        <div className="mCSB_draggerContainer">
                            <div
                                id="mCSB_1_dragger_vertical"
                                className="mCSB_dragger"
                                style={{ position: "absolute", minHeight: 50, height: 0, top: 0 }}
                            >
                                <div className="mCSB_dragger_bar" style={{ lineHeight: 50 }} />
                                <div className="mCSB_draggerRail" />
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="fixed-top fixedtophide">
                    <div className="navbarstk">
                        <button className="sidebarbtn sidebarCollapse d-block d-lg-none p-0 pr-1">
                            <i className="fal fa-bars" />
                        </button>
                        <a
                            role='button'
                            onClick={(e) => {
                                e.preventDefault();
                                this.checkurl(e, "/")
                            }}
                            className="custom-logo-link"
                            rel="home"
                            aria-current="page"
                        >
                            <img
                                width={365}
                                height={138}
                                src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                                className="custom-logo"
                                alt="SACASINO"
                                decoding="async"
                                srcSet="/build/web/igame-index-lobby-wm/img/logo.png?v=1 365w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/logo-1-1-300x113.png 300w"
                                sizes="(max-width: 365px) 100vw, 365px"
                            />
                        </a>
                        <div className="menu-menu-container">
                            <ul id="menu-menu-1" className="menu">
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-58">
                                    <a role='button'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.checkurl(e, "/")
                                        }}>หน้าหลัก</a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-59">
                                    <a role='button'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.checkurl(e, "/promotion")
                                        }}>
                                        โปรโมชั่น
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60">
                                    <a role='button'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.checkurl(e, "/slot")
                                        }}>
                                        เกมส์
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60">
                                    <a role='button'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.checkurl(e, "/deposit")
                                        }}>
                                        ฝาก
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-61">
                                    <a role='button'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.checkurl(e, "/withdraw")
                                        }}>
                                        ถอน
                                    </a>
                                </li>
                                {/* <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-65">
                                    <a href="/">ข่าวสาร</a>
                                </li> */}
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-64">
                                    <a href={this.state.linelink}>
                                        ติดต่อเรา
                                    </a>
                                </li>
                            </ul>
                        </div>{" "}
                        <div className="loginregishead" style={{ top: (!loginstate) ? "" : "-10px" }}>
                            {(loginstate != null) ? (!loginstate) ?
                                <div className="x-logged">
                                    <div>
                                        <button className="loginbtn">
                                            <a role='button'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.checkurl(e, "/signin")
                                                }}>เข้าสู่ระบบ</a>
                                        </button>{" "}
                                        <div className="textwidget" />
                                    </div>{" "}
                                    <div>
                                        <button className="registerbtn">
                                            <a role='button'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.checkurl(e, "/register")
                                                }}>สมัครสมาชิก</a>
                                        </button>{" "}
                                        <div className="textwidget" />
                                    </div>{" "}
                                </div>
                                : <div className="x-logged"  >
                                    <div className="-deposit-container d-none d-lg-block">
                                        <a
                                            onClick={(e) => {
                                                this.checkurl(e, "/deposit")
                                            }}
                                            className="text-white-v1 js-account-approve-aware btn -btn-deposit"
                                        >
                                            <div className="f-7">ฝากเงิน</div>
                                        </a>
                                    </div>
                                    <div className="-withdraw-container d-none d-lg-block">
                                        <a
                                            onClick={(e) => {
                                                this.checkurl(e, "/withdraw")
                                            }}
                                            className="text-white-v1 js-account-approve-aware btn -btn-withdraw"
                                        >
                                            <div className="f-7">ถอนเงิน</div>
                                        </a>
                                    </div>
                                    <div className="-profile-container" >
                                        <div className="d-none d-lg-block">
                                            <div className="-btn-wrapper">
                                                <div className="-inner-wrapper">
                                                    <div className="-balance-container">
                                                        <div className="-text-username11 pl-2">{username}</div> &nbsp;
                                                        &nbsp; &nbsp;{" "}
                                                        <div className="-user-balance js-user-balance f-sm-6 f-7">
                                                            <div className="-inner-box-wrapper">
                                                                <span id="customer-balance" className="js-customer-balance">
                                                                    <span className="-amount"><NumericFormat value={balance} displayType={'text'} thousandSeparator={true} /> {/**/}</span>
                                                                </span>{" "}
                                                                &nbsp; {/**/} &nbsp;{" "}
                                                            </div>
                                                            <button
                                                                type="button"
                                                                onClick={(e) => {
                                                                    this.recheccredit(e)
                                                                }}
                                                                id="btn-customer-balance-reload"
                                                                className="-btn-balance"
                                                            >
                                                                <i className="fas fa-sync-alt" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="x-profile-image"  >
                                                    <img
                                                        role="button"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                modal: true
                                                            });
                                                        }}
                                                        className="img-fluid -profile-image"
                                                        src={"/build/web/igame-index-lobby-wm/img/ic-rank-credit-top-01.png"}
                                                        alt="customer image"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-lg-none">
                                            <div className="js-ez-logged-sidebar -btn-mobile-wrapper" >
                                                <div className="-inner-wrapper">
                                                    <div className="-balance-container">
                                                        <div className="-text-username">{username}</div>
                                                        <div className="-user-balance js-user-balance f-sm-6 f-7">
                                                            <div className="-inner-box-wrapper">
                                                                <span id="customer-balance" className="js-customer-balance">
                                                                    <span className="-amount"><NumericFormat value={balance} displayType={'text'} thousandSeparator={true} />  {/**/}</span> &nbsp;{" "}

                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="x-profile-image">
                                                    <img
                                                        onClick={(e) => {
                                                            this.setState({
                                                                modal: true
                                                            });
                                                        }}
                                                        role="button"
                                                        className="img-fluid -profile-image"
                                                        src={"/build/web/igame-index-lobby-wm/img/ic-rank-credit-top-01.png"}
                                                        alt="customer image"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </div>
                    <div className="linefooter headlinetop" />
                </div>
                <div className="px-1" style={{ display: tabselect != 0 ? "" : "none" }}>
                    <div className="containerppp" >
                        <div className="container-lg" style={{ display: tabselect == 13 || tabselect == 10 || tabselect == 12 || tabselect == 14 || tabselect == 15 || tabselect == 17 || tabselect == 16 ? "" : "none" }}>
                            <div className="wrapper">
                                <div className="inwrapsec01"
                                    role="button" onClick={(e) => {
                                        this.checkurl(e, "/casino")
                                    }}

                                >
                                    <img
                                        width={500}
                                        height={200}
                                        src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/5-1.png"
                                        className="image wp-image-20  attachment-full size-full"
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                        decoding="async"
                                        srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/5-1.png 500w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/5-1-300x120.png 300w"
                                        sizes="(max-width: 500px) 100vw, 500px"
                                    />
                                </div>
                                <div className="inwrapsec01"
                                    role="button" onClick={(e) => {
                                        this.checkurl(e, "/slot")
                                    }}

                                >
                                    <img
                                        width={500}
                                        height={200}
                                        src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/6-1.png"
                                        className="image wp-image-21  attachment-full size-full"
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                        decoding="async"
                                        srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/6-1.png 500w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/6-1-300x120.png 300w"
                                        sizes="(max-width: 500px) 100vw, 500px"
                                    />
                                </div>
                                <div className="inwrapsec01" role="button" onClick={(e) => {
                                    this.checkurl(e, "/lotto")
                                }}>
                                    <img
                                        width={500}
                                        height={200}
                                        src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/7-1.png"
                                        className="image wp-image-22  attachment-full size-full"
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                        decoding="async"
                                        srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/7-1.png 500w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/7-1-300x120.png 300w"
                                        sizes="(max-width: 500px) 100vw, 500px"
                                    />
                                </div>
                                <div className="inwrapsec01"
                                    role="button" onClick={(e) => {
                                        this.checkurl(e, "/sport")
                                    }}>
                                    <img
                                        width={500}
                                        height={200}
                                        src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/8-1.png"
                                        className="image wp-image-23  attachment-full size-full"
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                        decoding="async"
                                        srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/8-1.png 500w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/8-1-300x120.png 300w"
                                        sizes="(max-width: 500px) 100vw, 500px"
                                    />
                                </div>{" "}
                            </div>
                        </div>
                        <div style={{ display: (tabselect === 13) ? "block" : "none" }}
                            data-elementor-type="wp-page"
                            data-elementor-id={978}
                            className="elementor elementor-978">
                            <section data-particle_enable="false" data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-e329bf3 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="e329bf3" data-element_type="section" >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c39cde5" data-id="c39cde5" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div className="elementor-element elementor-element-82f7754 elementor-widget elementor-widget-heading" data-id="82f7754" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h4 className="elementor-heading-title elementor-size-default">ค่ายคาสิโนมาแรง</h4>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-a29df5c elementor-widget elementor-widget-heading" data-id="a29df5c" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h5 className="elementor-heading-title elementor-size-default">ค่ายคาสิโนสุดฮิตตลอดกาลของประเทศไทย</h5>		</div>
                                            </div>
                                            <main className="container p-0">
                                                <div className="pt-1 pb-3 text-white-v1 w-scroll-height-casino">
                                                    <div className="x-category-provider js-game-scroll-container js-game-container animated animatedFadeInUp fadeInUp">
                                                        <div className="-games-list-container container">
                                                            <nav className="nav-menu" id="navbarProvider">
                                                                <ul className="nav nav-pills row animated animatedFadeInUp fadeInUp">
                                                                    {/**/}
                                                                    {slotlist.map((listitem, i) => (
                                                                        <li className="col-6 col-lg-2 col-md-3 nav-item -random-container -game-casino-macro-container p-1 pb-0 mb-2">
                                                                            <a onClick={(e) => {
                                                                                this.checkurl(e, "/listgameall/slot/" + listitem.partner)
                                                                            }}>
                                                                                <div
                                                                                    className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable example bg-card-item"
                                                                                    data-status="-cannot-entry -untestable bg-card-item"
                                                                                >
                                                                                    <div className="-inner-wrapper card-width-banner-games">
                                                                                        <div>
                                                                                        </div>
                                                                                        <picture>
                                                                                            <img
                                                                                                role="button"
                                                                                                className="w-100 hover-slot hoverTextCss"
                                                                                                lazy="loaded"
                                                                                                src={listitem.image}
                                                                                            />
                                                                                        </picture>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div>{/**/}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                </div>
                                            </main>
                                        </div >
                                    </div >
                                </div >
                            </section >
                        </div >
                        <div style={{ display: (tabselect === 10) ? "block" : "none" }}
                            data-elementor-type="wp-page" data-elementor-id={978} className="elementor elementor-978">
                            <section data-particle_enable="false" data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-e329bf3 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="e329bf3" data-element_type="section" >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c39cde5" data-id="c39cde5" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div className="elementor-element elementor-element-82f7754 elementor-widget elementor-widget-heading" data-id="82f7754" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h4 className="elementor-heading-title elementor-size-default">ค่ายคาสิโนมาแรง</h4>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-a29df5c elementor-widget elementor-widget-heading" data-id="a29df5c" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h5 className="elementor-heading-title elementor-size-default">ค่ายคาสิโนสุดฮิตตลอดกาลของประเทศไทย</h5>		</div>
                                            </div>
                                            <main className="container p-0">
                                                <div className="pt-1 pb-3 text-white-v1 w-scroll-height-casino">
                                                    <div className="x-category-provider js-game-scroll-container js-game-container animated animatedFadeInUp fadeInUp">
                                                        <div className="-games-list-container container">
                                                            <nav className="nav-menu" id="navbarProvider">
                                                                <ul className="nav nav-pills row animated animatedFadeInUp fadeInUp">
                                                                    {/**/}
                                                                    {categorylist.map((listitem, i) => (
                                                                        <li className="col-6 col-lg-2 col-md-3 nav-item -random-container -game-casino-macro-container p-1 pb-0 mb-2">
                                                                            <a onClick={(e) => {
                                                                                this.checkurl(e, "/listgameall/casino/" + listitem.partner)
                                                                            }}>
                                                                                <div
                                                                                    className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable example bg-card-item"
                                                                                    data-status="-cannot-entry -untestable bg-card-item"
                                                                                >
                                                                                    <div className="-inner-wrapper card-width-banner-games">
                                                                                        <div>
                                                                                        </div>
                                                                                        <picture>
                                                                                            <img
                                                                                                role="button"
                                                                                                className="w-100 hover-slot hoverTextCss"
                                                                                                lazy="loaded"
                                                                                                src={listitem.image}
                                                                                            />
                                                                                        </picture>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div>{/**/}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                </div>
                                            </main>
                                        </div >
                                    </div >
                                </div >
                            </section >
                        </div >
                        <div style={{ display: (tabselect === 12) ? "block" : "none" }}
                            data-elementor-type="wp-page" data-elementor-id={978} className="elementor elementor-978">
                            <section data-particle_enable="false" data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-e329bf3 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="e329bf3" data-element_type="section" >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c39cde5" data-id="c39cde5" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div className="elementor-element elementor-element-82f7754 elementor-widget elementor-widget-heading" data-id="82f7754" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h4 className="elementor-heading-title elementor-size-default">ค่ายเกมยิงปลา</h4>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-a29df5c elementor-widget elementor-widget-heading" data-id="a29df5c" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h5 className="elementor-heading-title elementor-size-default">ค่ายเกมยิงปลาสุดฮิตตลอดกาลของประเทศไทย</h5></div>
                                            </div>
                                            <main className="container p-0">
                                                <div className="pt-1 pb-3 text-white-v1 w-scroll-height-casino">
                                                    <div className="x-category-provider js-game-scroll-container js-game-container animated animatedFadeInUp fadeInUp">
                                                        <div className="-games-list-container container">
                                                            <nav className="nav-menu" id="navbarProvider">
                                                                <ul className="nav nav-pills row animated animatedFadeInUp fadeInUp">
                                                                    {/**/}
                                                                    {fishinglist.map((listitem, i) => (
                                                                        <li className="col-6 col-lg-2 col-md-3 nav-item -random-container -game-casino-macro-container p-1 pb-0 mb-2">
                                                                            <a onClick={(e) => {
                                                                                this.checkurl(e, "/listgameall/slot/" + listitem.partner)
                                                                            }}>
                                                                                <div
                                                                                    className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable example bg-card-item"
                                                                                    data-status="-cannot-entry -untestable bg-card-item"
                                                                                >
                                                                                    <div className="-inner-wrapper card-width-banner-games">
                                                                                        <div>
                                                                                        </div>
                                                                                        <picture>
                                                                                            <img
                                                                                                role="button"
                                                                                                className="w-100 hover-slot hoverTextCss"
                                                                                                lazy="loaded"
                                                                                                src={listitem.image}
                                                                                            />
                                                                                        </picture>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div>{/**/}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                </div>
                                            </main>
                                        </div >
                                    </div >
                                </div >
                            </section >
                        </div >
                        <div style={{ display: (tabselect === 14) ? "block" : "none" }}
                            data-elementor-type="wp-page" data-elementor-id={978} className="elementor elementor-978">
                            <section data-particle_enable="false" data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-e329bf3 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="e329bf3" data-element_type="section" >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c39cde5" data-id="c39cde5" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div className="elementor-element elementor-element-82f7754 elementor-widget elementor-widget-heading" data-id="82f7754" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h4 className="elementor-heading-title elementor-size-default">กีฬาของเรา</h4>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-a29df5c elementor-widget elementor-widget-heading" data-id="a29df5c" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h5 className="elementor-heading-title elementor-size-default">ค่ายคาสิโนสุดฮิตตลอดกาลของประเทศไทย</h5>		</div>
                                            </div>
                                            <section
                                                className="elementor-section elementor-inner-section elementor-element elementor-element-e41f338 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e41f338" data-element_type="section">
                                                <div className="elementor-container elementor-column-gap-no">
                                                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a7e393c" data-id="a7e393c"
                                                        data-element_type="column">
                                                        <main className="container p-0">
                                                            <div className="pt-1 pb-3 text-white-v1 w-scroll-height-casino">
                                                                <div className="x-category-provider js-game-scroll-container js-game-container animated animatedFadeInUp fadeInUp">
                                                                    <div className="-games-list-container container">
                                                                        <nav className="nav-menu" id="navbarProvider">
                                                                            <ul className="nav nav-pills row animated animatedFadeInUp fadeInUp">
                                                                                {/**/}
                                                                                {sportlist.map((listitem, i) => (
                                                                                    <li className="col-6 col-lg-2 col-md-3 nav-item -random-container -game-casino-macro-container p-1 pb-0 mb-2">
                                                                                        <a onClick={(e) => {
                                                                                            this.checkurl(e, "/listgameall/sport/" + listitem.partner)
                                                                                        }}>
                                                                                            <div
                                                                                                className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable example bg-card-item"
                                                                                                data-status="-cannot-entry -untestable bg-card-item"
                                                                                            >
                                                                                                <div className="-inner-wrapper card-width-banner-games">
                                                                                                    <div>
                                                                                                    </div>
                                                                                                    <picture>
                                                                                                        <img
                                                                                                            role="button"
                                                                                                            className="w-100 hover-slot hoverTextCss"
                                                                                                            lazy="loaded"
                                                                                                            src={listitem.image}
                                                                                                        />
                                                                                                    </picture>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div>{/**/}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </a>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </nav>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </main>
                                                    </div >
                                                </div >
                                            </section >
                                        </div >
                                    </div >
                                </div >
                            </section >
                        </div >
                        <div style={{ display: (tabselect === 17) ? "block" : "none" }}
                            data-elementor-type="wp-page" data-elementor-id={978} className="elementor elementor-978">
                            <section data-particle_enable="false" data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-e329bf3 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="e329bf3" data-element_type="section" >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c39cde5" data-id="c39cde5" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div className="elementor-element elementor-element-82f7754 elementor-widget elementor-widget-heading" data-id="82f7754" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h4 className="elementor-heading-title elementor-size-default">หวยมาแรง</h4>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-a29df5c elementor-widget elementor-widget-heading" data-id="a29df5c" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h5 className="elementor-heading-title elementor-size-default">ค่ายหวยสุดฮิตตลอดกาลของประเทศไทย</h5>		</div>
                                            </div>
                                            <section
                                                className="elementor-section elementor-inner-section elementor-element elementor-element-e41f338 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e41f338" data-element_type="section">
                                                <div className="elementor-container elementor-column-gap-no">
                                                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a7e393c" data-id="a7e393c"
                                                        data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-f2c6016 elementor-widget elementor-widget-gallery" data-id="f2c6016"
                                                                data-settings="{&quot;columns&quot;:5,&quot;columns_tablet&quot;:4,&quot;columns_mobile&quot;:2,&quot;aspect_ratio&quot;:&quot;1:1&quot;,&quot;lazyload&quot;:&quot;yes&quot;,&quot;gallery_layout&quot;:&quot;grid&quot;,&quot;gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]},&quot;gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]},&quot;gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]},&quot;overlay_background&quot;:&quot;yes&quot;,&quot;content_hover_animation&quot;:&quot;fade-in&quot;}"
                                                                data-element_type="widget">
                                                                <div className="elementor-widget-container">
                                                                    <div className="elementor-gallery__container e-gallery-container e-gallery-grid e-gallery--ltr e-gallery--lazyload wrapper-happy " >
                                                                        {lottolist.map((listitem, i) => (
                                                                            <div className="e-gallery-item elementor-gallery-item elementor-animated-content" style={{ "-column": 0, "-row": 0 }}>
                                                                                <a role="button" onClick={(e) => {

                                                                                    this.checkurl(e, "/listgameall/slot/" + listitem.partner)
                                                                                }}>
                                                                                    <div className="e-gallery-image elementor-gallery-item__image e-gallery-image-loaded" data-thumbnail="https://ideabet.live/theme/ufa365/wp-content/uploads/2022/04/slot1-300x300.png" data-width={300}
                                                                                        data-height={300} alt=""
                                                                                        style={{ backgroundImage: "url(" + listitem.image + ")" }} />
                                                                                    <div className="elementor-gallery-item__overlay" />
                                                                                </a>
                                                                            </div>
                                                                        ))}
                                                                    </div >

                                                                </div >
                                                            </div >
                                                        </div >
                                                    </div >
                                                </div >
                                            </section >
                                        </div >
                                    </div >
                                </div >
                            </section >
                        </div >
                        <div style={{ display: (tabselect === 15) ? "block" : "none" }}
                            data-elementor-type="wp-page" data-elementor-id={978} className="elementor elementor-978">
                            <section data-particle_enable="false" data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-e329bf3 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="e329bf3" data-element_type="section" >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c39cde5" data-id="c39cde5" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div className="elementor-element elementor-element-82f7754 elementor-widget elementor-widget-heading" data-id="82f7754" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h4 className="elementor-heading-title elementor-size-default">{window.location.pathname.split("/")[3]} </h4>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-a29df5c elementor-widget elementor-widget-heading" data-id="a29df5c" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h5 className="elementor-heading-title elementor-size-default">{" " + listitallgame.length + " Games"}  </h5>		</div>
                                            </div>
                                            <main className="container p-0">
                                                <div className="pt-1 pb-3 text-white-v1 w-scroll-height-casino">
                                                    <div className="x-category-provider js-game-scroll-container js-game-container animated animatedFadeInUp fadeInUp">
                                                        <div className="-games-list-container container">
                                                            <nav className="nav-menu" id="navbarProvider">
                                                                <ul className="nav nav-pills row animated animatedFadeInUp fadeInUp">
                                                                    {/**/}
                                                                    {listitallgame.map((listitem, i) => (
                                                                        <li className="col-6 col-lg-2 col-md-3 nav-item -random-container -game-casino-macro-container p-1 pb-0 mb-2">
                                                                            <a href={() => false}
                                                                                onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)} >
                                                                                <div className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable example bg-card-item"
                                                                                    data-status="-cannot-entry -untestable bg-card-item"
                                                                                >
                                                                                    <div className="-inner-wrapper card-width-banner-games">
                                                                                        <div>
                                                                                        </div>
                                                                                        <picture>
                                                                                            <img
                                                                                                role="button"
                                                                                                className="w-100 hover-slot hoverTextCss"
                                                                                                lazy="loaded"
                                                                                                src={listitem.image}
                                                                                            />
                                                                                        </picture>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div>{/**/}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                </div>
                                            </main>
                                        </div >
                                    </div >
                                </div >
                            </section >
                        </div >
                        <div style={{ display: tabselect === 23 ? "block" : "none" }}
                            data-elementor-type="wp-page"
                            data-elementor-id={154}
                            className="elementor elementor-154"
                        >
                            <Signin />
                        </div>
                        <div style={{ display: tabselect === 22 ? "block" : "none" }}
                            data-elementor-type="wp-page"
                            data-elementor-id={154}
                            className="elementor elementor-154"
                        >
                            <Register />
                        </div>
                        <div style={{ display: tabselect === 8 ? "block" : "none" }}
                            data-elementor-type="wp-page"
                            data-elementor-id={154}
                            className="elementor elementor-154"
                        >
                            <Profile props={this.state} />
                        </div>
                        <div style={{ display: tabselect === 3 ? "block" : "none" }}
                            data-elementor-type="wp-page"
                            data-elementor-id={154}
                            className="elementor elementor-154"
                        >
                            <Statement props={this.state} />
                        </div>
                        <div style={{ display: tabselect === 4 ? "block" : "none" }}
                            data-elementor-type="wp-page"
                            data-elementor-id={154}
                            className="elementor elementor-154"
                        >
                            <Afficiate props={this.state} />
                        </div>
                        <div style={{ display: tabselect === 25 ? "block" : "none" }}
                            data-elementor-type="wp-page"
                            data-elementor-id={154}
                            className="elementor elementor-154"
                        >
                            <Loss props={this.state} />
                        </div>
                        <div style={{ display: tabselect === 7 ? "block" : "none" }}
                            data-elementor-type="wp-page"
                            data-elementor-id={154}
                            className="elementor elementor-154"
                        >
                            <Promotion props={this.state} />
                        </div>
                        <div style={{ display: tabselect === 16 ? "block" : "none" }}
                            data-elementor-type="wp-page"
                            data-elementor-id={154}
                            className="elementor elementor-154"
                        >
                            <div className="padding-router-view">
                                <div className="container p-0 x-margin-top-v3">
                                    <div className="row m-0 border-shadow-title">
                                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                            <div className="text-center bg-title-web">
                                                <div>
                                                    <div className="text-title text-white-v1 text-center">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="text-white-v1-deposit">
                                                                <a role="button" onClick={(e) => {
                                                                    this.checkurl(e, "/")
                                                                }}>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={25}
                                                                        height={25}
                                                                        fill="currentColor"
                                                                        className="bi bi-arrow-left-circle text-color-back"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                            <div className="text-white-v1-deposit">
                                                                <h5>กิจกรรม</h5>
                                                            </div>
                                                            <div>{/**/}</div>
                                                        </div>
                                                        <hr className="mt-0" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="bg-event-box animated animatedFadeInUp fadeInUp"
                                                data-v-8a7c0ea5=""
                                            >
                                                <div className="banner-event" data-v-8a7c0ea5="">
                                                    <div className="px-0 card-width-banner" data-v-8a7c0ea5="">
                                                        <div
                                                            className="flicking-viewport"
                                                            data-v-8a7c0ea5=""
                                                            style={{
                                                                userSelect: "none",
                                                                WebkitUserDrag: "none",
                                                                touchAction: "pan-y"
                                                            }}
                                                        >
                                                            <Slider {...settings}>
                                                                <div
                                                                    className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                                                    data-slick-index={-2}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}

                                                                >
                                                                    <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                                                        <picture>
                                                                            <img
                                                                                className="img-fluid -slick-item -item-9"
                                                                                src="/build/web/igame-index-lobby-wm/img/1.jpg"
                                                                                alt="First slide"
                                                                                width={1200}
                                                                                height={590}
                                                                                style={{ borderRadius: "0.5rem !important" }}
                                                                            />
                                                                        </picture>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                                                    data-slick-index={-1}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}

                                                                >
                                                                    <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                                                        <picture>
                                                                            <img
                                                                                className="img-fluid -slick-item -item-9"
                                                                                src="/build/web/igame-index-lobby-wm/img/1.jpg"
                                                                                alt="First slide"
                                                                                width={1200}
                                                                                height={590}
                                                                                style={{ borderRadius: "0.5rem !important" }}
                                                                            />
                                                                        </picture>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                                                    data-slick-index={-1}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}
                                                                >
                                                                    <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                                                        <picture>
                                                                            <img
                                                                                className="img-fluid -slick-item -item-9"
                                                                                src="/build/web/igame-index-lobby-wm/img/1.jpg"
                                                                                alt="First slide"
                                                                                width={1200}
                                                                                height={590}
                                                                                style={{ borderRadius: "0.5rem !important" }}
                                                                            />
                                                                        </picture>
                                                                    </div>
                                                                </div>
                                                            </Slider >
                                                            <div
                                                                className="flicking-pagination flicking-pagination-bullets"
                                                                data-v-8a7c0ea5=""
                                                            >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row m-1" data-v-8a7c0ea5="">
                                                    <div className="col-6 p-0" data-v-8a7c0ea5="">
                                                        <div className="border-box-credit" data-v-8a7c0ea5="">
                                                            <div
                                                                className="d-flex justify-content-between"
                                                                data-v-8a7c0ea5=""
                                                            >
                                                                <div className="icon" data-v-8a7c0ea5="">
                                                                    <div data-v-8a7c0ea5="">
                                                                        <div
                                                                            role="button"
                                                                            className="d-flex justify-content-between"
                                                                            data-v-8a7c0ea5=""
                                                                        >
                                                                            <div data-v-8a7c0ea5="">
                                                                                <small
                                                                                    className="font-play-refer"
                                                                                    data-v-8a7c0ea5=""
                                                                                >
                                                                                    ยอดเงินในเกม
                                                                                </small>
                                                                                <div
                                                                                    className="text-white d-flex align-items-center"
                                                                                    data-v-8a7c0ea5=""
                                                                                >
                                                                                    <span data-v-8a7c0ea5="">
                                                                                        <img
                                                                                            className="lang-type-bath"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d729c93e-e40d-4c6f-db8b-be26f954d200/public"
                                                                                            alt=""
                                                                                            data-v-8a7c0ea5=""
                                                                                        />
                                                                                    </span>
                                                                                    <b
                                                                                        className="p-2-l-r-box-event"
                                                                                        data-v-8a7c0ea5=""
                                                                                    >
                                                                                        0
                                                                                    </b>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="icon" data-v-8a7c0ea5="">
                                                                    <a
                                                                        onClick={(e) => {
                                                                            this.checkurl(e, "/")
                                                                        }}
                                                                        role="button"
                                                                        id="icon-refresh-credit"
                                                                        className="mt-2 mb-2"
                                                                        data-v-8a7c0ea5=""
                                                                    >
                                                                        <span data-v-8a7c0ea5="">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={15}
                                                                                height={15}
                                                                                fill="currentColor"
                                                                                className="bi bi-arrow-clockwise text-white-v1"
                                                                                viewBox="0 0 16 16"
                                                                                data-v-8a7c0ea5=""
                                                                            >
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                                                                                    data-v-8a7c0ea5=""
                                                                                />
                                                                                <path
                                                                                    d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
                                                                                    data-v-8a7c0ea5=""
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row animated animatedFadeInUp fadeInUp">
                                                <div className="col-lg-4 text-center p-1 col-3">
                                                    <a role='button' onClick={(e) => {
                                                        this.checkurl(e, "/refer/overview")
                                                    }} >
                                                        <div className="bg-card-item m-1" >
                                                            <div className="p-1 bg-mission-button example">
                                                                <img
                                                                    className="w-100 img-hover-event"
                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/27853edf-e63b-44fe-d4f0-ca075d6e2a00/public"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="p-1">
                                                                <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                    <b className="font-event-size-1 text-ellipsis">
                                                                        แนะนำเพื่อน
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-lg-4 text-center p-1 col-3">
                                                    <a
                                                        role='button' onClick={(e) => {
                                                            this.checkurl(e, "/loss")
                                                        }} >
                                                        <div className="bg-card-item m-1">
                                                            <div className="p-1 bg-mission-button example">
                                                                <img
                                                                    className="w-100 img-hover-event"
                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/9dde2261-a3f8-4237-af7b-7566b82b0700/public"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="p-1">
                                                                <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                    <b className="font-event-size-1 text-ellipsis">
                                                                        คืนยอดเสีย
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-lg-4 text-center p-1 col-3">
                                                    <a
                                                        role='button' onClick={(e) => {
                                                            this.checkurl(e, "/promotion")
                                                        }}>
                                                        <div className="bg-card-item m-1">
                                                            <div className="p-1 bg-mission-button example">
                                                                <img
                                                                    className="w-100 img-hover-event"
                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/211a5b05-cd76-41f5-cd5b-396825326500/public"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="p-1">
                                                                <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                    <b className="font-event-size-1 text-ellipsis">
                                                                        โปรโมชั่น
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                {/* <div className="col-lg-4 text-center p-1 col-3">
                                                <a href="/promotion" >
                                                    <div className="bg-card-item m-1">
                                                        <div className="p-1 bg-mission-button example">
                                                            <img
                                                                className="w-100 img-hover-event"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57a68c1c-3257-422e-c072-2b2bbf0f8200/public"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="p-1">
                                                            <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                <b className="font-event-size-1 text-ellipsis">
                                                                    ภารกิจ
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </a>
                                                </div> */}
                                                {/* <div className="col-lg-4 text-center p-1 col-3">
                                                    <div className="bg-card-item m-1">
                                                        <div className="p-1 bg-mission-button example">
                                                            <img
                                                                className="w-100 img-hover-event"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7e052147-f4f9-44b6-25cd-ffc0d45c7600/public"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="p-1">
                                                            <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                <b className="font-event-size-1 text-ellipsis">
                                                                    กิจกรรมเปิดไพ่
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-4 text-center p-1 col-3">
                                                    <div className="bg-card-item m-1">
                                                        <div className="p-1 bg-mission-button example">
                                                            <img
                                                                className="w-100 img-hover-event"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/fb674130-3318-46c3-c482-dd8a0c938700/public"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="p-1">
                                                            <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                <b className="font-event-size-1 text-ellipsis">
                                                                    แลกของรางวัล
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 text-center p-1 col-3">
                                                    <div className="bg-card-item m-1">
                                                        <div className="p-1 bg-mission-button example">
                                                            <img
                                                                className="w-100 img-hover-event"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d8563ba2-b284-4bad-4313-dc02549bd200/public"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="p-1">
                                                            <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                <b className="font-event-size-1 text-ellipsis">
                                                                    คืนยอดเดิมพัน
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 text-center p-1 col-3">
                                                    <div className="bg-card-item m-1">
                                                        <div className="p-1 bg-mission-button example">
                                                            <img
                                                                className="w-100 img-hover-event"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/caf1df64-acb0-41ad-6cae-4f2f85b89c00/public"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="p-1">
                                                            <div className="name-progress-circle-number text-name text-ellipsis align-items-center">
                                                                <b className="font-event-size-1 text-ellipsis">
                                                                    จัดอันดับ
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: tabselect === 1 ? "block" : "none" }}
                            data-elementor-type="wp-page"
                            data-elementor-id={154}
                            className="elementor elementor-154"
                        >
                            <section className="-contact-wrapper row m-0">
                                <div className="-games-provider-wrapper pl-0 pr-0 mx-auto -menu-index-page-bg col-12">
                                    {/**/}
                                </div>
                                <div className="-games-index-page col-12">
                                    <div className="mb-2 text-white-v1">
                                        {/**/}
                                        <div className="padding-router-view">
                                            <div>
                                                <div className="row m-0 border-shadow-title" >
                                                    <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                                        <div className="text-center bg-title-web">
                                                            <div>
                                                                <div className="text-title text-white-v1 text-center">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="text-white-v1-deposit">
                                                                            <a role="button" onClick={(e) => {
                                                                                this.checkurl(e, "/")
                                                                            }}>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={25}
                                                                                    height={25}
                                                                                    fill="currentColor"
                                                                                    className="bi bi-arrow-left-circle text-color-back"
                                                                                    viewBox="0 0 16 16"
                                                                                >
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                                                                    />
                                                                                </svg>
                                                                            </a>
                                                                        </div>
                                                                        <div className="text-white-v1-deposit">
                                                                            <h5>ฝากเงิน</h5>
                                                                        </div>
                                                                        <div>{/**/}</div>
                                                                    </div>
                                                                    <hr className="mt-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="x-login-index animated animatedFadeInUp fadeInUp">
                                                            <div>
                                                                <div className="pt-1 pb-1">
                                                                    <ul className="nav nav-tabs d-flex justify-content-center">
                                                                        <li className="nav-item text-center w-50 w-margin-padding">
                                                                            <a
                                                                                className={this.state.tabselectdeposit == 1 ? "nav-link active" : "nav-link"}
                                                                                aria-current="page"
                                                                                href="#"
                                                                                onClick={(e) => {
                                                                                    this.checktab(e, 1)
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    className="img-bank-title"
                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7c0dc589-eb20-4725-98df-0531730fff00/public"
                                                                                    alt=""
                                                                                />
                                                                                <span className="text-font-title-deposit">
                                                                                    ธนาคาร
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li className="nav-item text-center w-50 w-margin-padding">
                                                                            <a className={this.state.tabselectdeposit == 3 ? "nav-link active" : "nav-link"}
                                                                                onClick={(e) => {
                                                                                    this.checktab(e, 3)
                                                                                }}
                                                                                aria-current="page" href="#">
                                                                                <img
                                                                                    className="img-bank-title"
                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a152f75f-0588-49aa-2836-4d9528f69000/public"
                                                                                    alt=""
                                                                                />
                                                                                <span className="text-font-title-deposit">
                                                                                    ทรูมันนี่
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        {/* <li className="nav-item text-center w-50 w-margin-padding">
                                                                <a className={this.state.tabselect == 2 || this.state.tabselectsec == 1 ? "nav-link active" : "nav-link"}
                                                                    onClick={(e) => {
                                                                        this.checktab(e, 2)
                                                                    }}
                                                                    aria-current="page" href="#">
                                                                    <img
                                                                        className="img-bank-title"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/47a8d8e8-6424-4e84-41f7-f119c3b14e00/public"
                                                                        alt=""
                                                                    />
                                                                    <span className="text-font-title-deposit">
                                                                        สเเกน QR ฝากเงิน
                                                                    </span>
                                                                </a>
                                                            </li> */}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="row m-0 border-shadow-title" style={{ display: this.state.tabselectdeposit == 1 ? "" : "none" }}>
                                                    <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                                        <div className="p-1 bg-deposit-button example animated animatedFadeInUp fadeInUp">
                                                            <div className="mt-3">
                                                                <div className="x-login-index">
                                                                    <div>
                                                                        <div>
                                                                            <div className="mt-3">
                                                                                <div className="text-white-v1-deposit">
                                                                                    <div>
                                                                                        <h6 className="mt-1 text-center text-white-v1-deposit">
                                                                                            <u>บัญชีของคุณ</u>
                                                                                        </h6>
                                                                                        <div>
                                                                                            <div>
                                                                                                <div className="box-content p-1 p-sm-1">
                                                                                                    <div className="flex justify-between mx-auto">
                                                                                                        <div className="sub-content w-100 p-3 text-white-v1-deposit">
                                                                                                            <div className="text-center">
                                                                                                                <img
                                                                                                                    className="rounded-full w-10 mb-2 flex"
                                                                                                                    src={accnumber !== null ? accnumberx.img_path : ""}
                                                                                                                    style={{ width: 38 }}
                                                                                                                />{" "}
                                                                                                                &nbsp; {accnumber !== null ? accnumberx.fullname_th : ""}
                                                                                                            </div>
                                                                                                            <div className="text-center">
                                                                                                                {firstname} &nbsp; | &nbsp;
                                                                                                                {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                                                                                                            </div>
                                                                                                            <div className="text-center pt-2">
                                                                                                                <small className="f-15 text-deposit">
                                                                                                                    (
                                                                                                                    กรุณาโอนเงินด้วยบัญชีที่ระบบแจ้งเท่านั้น
                                                                                                                    )
                                                                                                                </small>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            {mainacc !== null ?
                                                                                                <div className="text-white-v1-deposit text-center">
                                                                                                    <h6 className="mb-2 mt-2 text-center">
                                                                                                        <u>{"โอนเข้าบัญชี * ขั้นต่ำ " + limits.min + " สูงสุด " + limits.max + " บาท"}</u>
                                                                                                    </h6>
                                                                                                    <div>
                                                                                                        <div className="flex justify-between mb-1 mx-auto box-content">
                                                                                                            <div className="sub-content w-100 p-3 px-lg-5">
                                                                                                                <div className="text-center">
                                                                                                                    <img
                                                                                                                        className="rounded-full w-10 mb-2 flex"
                                                                                                                        src={mainacc.img_path}
                                                                                                                        style={{ width: 38 }}
                                                                                                                    />{" "}
                                                                                                                    &nbsp;{mainacc.fullname_th}
                                                                                                                </div>
                                                                                                                <div className="text-center">
                                                                                                                    {mainacc.first_name_en + " " + mainacc.last_name_en} :&nbsp; {
                                                                                                                        mainacc.accnumber.substring(0, 3) + "-" + mainacc.accnumber.substring(3, 9) + "-" + mainacc.accnumber.substring(9, 12)
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="text-center pt-2">
                                                                                                                    <a href={() => false} style={{ cursor: "pointer" }}
                                                                                                                        onClick={() => this.copyCode(mainacc.accnumber)}
                                                                                                                    //  onClick={() => navigator.clipboard.writeText(mainacc.accnumber)}
                                                                                                                    >
                                                                                                                        <div
                                                                                                                            className="btn v-login"
                                                                                                                            style={{
                                                                                                                                fontSize: 16,
                                                                                                                                cursor: "pointer"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            เลขบัญชี :&nbsp;{" "}
                                                                                                                            <span id="copy-banktransfer">
                                                                                                                                {mainacc.accnumber}
                                                                                                                            </span>{" "}
                                                                                                                            &nbsp;&nbsp;
                                                                                                                            <span
                                                                                                                                className="badge text-bg-white p-1"
                                                                                                                                style={{
                                                                                                                                    borderRadius: "33.9916px"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                &nbsp; คัดลอก &nbsp;
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                : ""}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text-white-v1-deposit text-center">
                                                                <div className="box-content p-0 animated animatedFadeInUp fadeInUp mt-3">
                                                                    <div className="sub-content p-3">
                                                                        <div className="d-flex justify-content-between">
                                                                            <div className="text-white-v1-deposit pt-1">
                                                                                <img
                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                                                    alt=""
                                                                                    style={{ width: 30 }}
                                                                                />{" "}
                                                                                &nbsp; รับโปรโมชั่นอัตโนมัติ
                                                                            </div>
                                                                            <div className="text-white-v1-deposit">
                                                                                <div className="form-check form-switch">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        role="switch"
                                                                                        id="flexSwitchCheckDefault"
                                                                                        style={{ width: "6em", padding: 12 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/**/}
                                                            {/**/}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row m-0 border-shadow-title" style={{ display: this.state.tabselect == 2 ? "" : "none" }}>
                                                    <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                                        <div className="p-1 bg-deposit-button example animated animatedFadeInUp fadeInUp">
                                                            <div className="container p-0 x-margin-top-v3">
                                                                <div className="row m-0 border-shadow-title">
                                                                    <div className="col-12 p-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto my-auto -deposit">
                                                                        <div className="mt-3 -deposit">
                                                                            <div className="animated animatedFadeInUp fadeInUp">
                                                                                <div className="card-promptpay text-center pt-3">
                                                                                    <img
                                                                                        className="img-logo-promptpay"
                                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/9c9ef88c-a34b-4aa6-1a42-54ff6cd9a800/public"
                                                                                        alt=""
                                                                                    />
                                                                                    <span className="">พร้อมเพย์ / ธนาคารเท่านั้น</span>
                                                                                    <div className="pt-3 pb-2 text-left p-detail-promptpay">
                                                                                        <div className="pt-2 pb-2">
                                                                                            <small className="p">ระบุจำนวนเงินที่ต้องเติม</small>
                                                                                        </div>
                                                                                        <input
                                                                                            type="text"
                                                                                            id="depositamount"
                                                                                            onChange={this.onChangexdep}
                                                                                            value={this.state.depositamount}
                                                                                            placeholder="กรอกจำนวนเงิน"
                                                                                            maxLength={4}
                                                                                            minLength={4}
                                                                                            name="number"
                                                                                            className="form-control promptpay-border-radius"
                                                                                        />
                                                                                        <div className="mb-1 mt-2">
                                                                                            <label htmlFor="price" className="mt-1 mb-1 text-left">
                                                                                                เลือกจำนวน
                                                                                            </label>
                                                                                            <div className="mb-4 row m-0">
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1"
                                                                                                        onClick={(e) => this.handleClickdeposit(e, 100)}>
                                                                                                        <div className="text-white-v1">100</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1"
                                                                                                        onClick={(e) => this.handleClickdeposit(e, 300)}>
                                                                                                        <div className="text-white-v1">300</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 500)}>
                                                                                                        <div className="text-white-v1">500</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 1000)}>
                                                                                                        <div className="text-white-v1">1,000</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 3000)}>
                                                                                                        <div className="text-white-v1">3,000</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 5000)}>
                                                                                                        <div className="text-white-v1">5,000</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 10000)}>
                                                                                                        <div className="text-white-v1">10,000</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 30000)}>
                                                                                                        <div className="text-white-v1">30,000</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 50000)}>
                                                                                                        <div className="text-white-v1">50,000</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="pt-2 pb-2">
                                                                                            <button className="btn -btn-deposit w-100"
                                                                                                onClick={(e) => this.confirmdeposit(e, 0)}
                                                                                            >
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width={16}
                                                                                                    height={16}
                                                                                                    fill="currentColor"
                                                                                                    className="bi bi-check-circle-fill"
                                                                                                    viewBox="0 0 16 16"
                                                                                                >
                                                                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                                                </svg>{" "}
                                                                                                สร้าง QR Code เพื่อชำระ
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text-white-v1-deposit text-center">
                                                                <div className="box-content p-0 animated animatedFadeInUp fadeInUp mt-3">
                                                                    <div className="sub-content p-3">
                                                                        <div className="d-flex justify-content-between">
                                                                            <div className="text-white-v1-deposit pt-1">
                                                                                <img
                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                                                    alt=""
                                                                                    style={{ width: 30 }}
                                                                                />{" "}
                                                                                &nbsp; รับโปรโมชั่นอัตโนมัติ
                                                                            </div>
                                                                            <div className="text-white-v1-deposit">
                                                                                <div className="form-check form-switch">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        role="switch"
                                                                                        id="flexSwitchCheckDefault"
                                                                                        style={{ width: "6em", padding: 12 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/**/}
                                                            {/**/}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row m-0 border-shadow-title" style={{ display: this.state.tabselectsec == 1 ? "" : "none" }}>
                                                    <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                                        <div className="p-1 bg-deposit-button example animated animatedFadeInUp fadeInUp">
                                                            <div className="container p-0 x-margin-top-v3">
                                                                <div className="row m-0 border-shadow-title">
                                                                    <div className="col-12 p-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto my-auto -deposit">
                                                                        <div className="mt-3 -deposit">
                                                                            <div className="animated animatedFadeInUp fadeInUp">
                                                                                <div className="card-promptpay text-center pt-3">
                                                                                    <div className="card-body-promptpay">
                                                                                        <div className="card card-2-promptpay">
                                                                                            <div className="card-header card-2-promptpay-header">
                                                                                                {" "}
                                                                                                QR Code สำหรับชำระเงิน
                                                                                            </div>
                                                                                            <div className="card-body">
                                                                                                <div className="p-2 mb-2 bg-success-subtle">
                                                                                                    <small className="text">
                                                                                                        QR Code
                                                                                                        สามารถสเเกนจ่ายได้เพียงครั้งเดี่ยวเท่านั้นโปรดอย่าสเเกนจ่ายซ้ำ
                                                                                                    </small>
                                                                                                </div>
                                                                                                <div className="prt-2">
                                                                                                    <div className="img-qr-code">
                                                                                                        {this.state.qrcode !== null ?
                                                                                                            <img
                                                                                                                src={this.state.qrcode}
                                                                                                                className="-img-text-promotion img-fluid ls-is-cached lazyloaded"
                                                                                                                alt=""
                                                                                                                style={{ backgroundColor: "black", height: 196, width: 196 }}
                                                                                                                height={196}
                                                                                                                width={196}
                                                                                                            /> : ""
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="qtx-number pt-2">
                                                                                                        <h5 className="font-qtx-number">
                                                                                                            <b>
                                                                                                                {this.state.depositamount}{" "}
                                                                                                                <span>
                                                                                                                    <img
                                                                                                                        className="lang-type-bath"
                                                                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d729c93e-e40d-4c6f-db8b-be26f954d200/public"
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                </span>
                                                                                                            </b>
                                                                                                        </h5>
                                                                                                    </div>
                                                                                                    <hr className="m-1" />
                                                                                                    <div className="qtx-date-time">
                                                                                                        <h5 className="font-qtx-date-time m-0">
                                                                                                            <b>{this.state.counter > 0 ? this.state.counter + " วินาที" : ""} </b>
                                                                                                        </h5>
                                                                                                        <h5 className="text-danger remark-date-time m-0">
                                                                                                            โปรดชำระเงินภายในเวลาที่กำหนด
                                                                                                        </h5>
                                                                                                    </div>
                                                                                                    <hr className="m-1" />
                                                                                                    <div className="bg-success-subtle p-2 mb-2 text-color-details">
                                                                                                        <div className="font-details-qr-code">
                                                                                                            {" "}
                                                                                                            หากเกิดปัญหาฝากเงินหรืออื่นๆ
                                                                                                            กรุณาติดต่อเจ้าหน้าที่{" "}
                                                                                                            <b className="text-danger">
                                                                                                                <a role="button">
                                                                                                                    Line ID : {this.state.linelink}
                                                                                                                </a>
                                                                                                            </b>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <a role="button" className="btn btn-danger w-100">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                            width={16}
                                                                                                            height={16}
                                                                                                            fill="currentColor"
                                                                                                            className="bi bi-x-circle-fill"
                                                                                                            viewBox="0 0 16 16"
                                                                                                        >
                                                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                                                                        </svg>{" "}
                                                                                                        {this.state.counter > 0 ? "ยกเลิกรายการ" : "กรุณาทำรายการใหม่"}
                                                                                                    </a>
                                                                                                    {/**/}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text-white-v1-deposit text-center">
                                                                <div className="box-content p-0 animated animatedFadeInUp fadeInUp mt-3">
                                                                    <div className="sub-content p-3">
                                                                        <div className="d-flex justify-content-between">
                                                                            <div className="text-white-v1-deposit pt-1">
                                                                                <img
                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                                                    alt=""
                                                                                    style={{ width: 30 }}
                                                                                />{" "}
                                                                                &nbsp; รับโปรโมชั่นอัตโนมัติ
                                                                            </div>
                                                                            <div className="text-white-v1-deposit">
                                                                                <div className="form-check form-switch">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        role="switch"
                                                                                        id="flexSwitchCheckDefault"
                                                                                        style={{ width: "6em", padding: 12 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/**/}
                                                            {/**/}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row m-0 border-shadow-title" style={{ display: this.state.tabselectdeposit == 3 ? "" : "none" }}>
                                                    <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                                        <div className="p-1 bg-deposit-button example animated animatedFadeInUp fadeInUp">
                                                            <div className="mt-3">
                                                                <div className="x-login-index">
                                                                    <div>
                                                                        <div>
                                                                            <div className="mt-3">
                                                                                <div className="text-white-v1-deposit">
                                                                                    <div>
                                                                                        <div>
                                                                                            {trueaccount !== null ?
                                                                                                <div className="text-white-v1-deposit text-center">
                                                                                                    <h6 className="mb-2 mt-2 text-center">
                                                                                                        <u>โอนเข้าบัญชี</u>
                                                                                                    </h6>
                                                                                                    <div>
                                                                                                        <div className="flex justify-between mb-1 mx-auto box-content">
                                                                                                            <div className="sub-content w-100 p-3 px-lg-5">
                                                                                                                <div className="text-center">
                                                                                                                    <img
                                                                                                                        className="rounded-full w-10 mb-2 flex"
                                                                                                                        src="https://play-lh.googleusercontent.com/6I2IYbIg4rhGUgs0UxP_5q6wmJmlBjBrlQ9f0_FAN94yOzwmrtEteifCdPPd1-chY_NX"
                                                                                                                        style={{ width: 38 }}
                                                                                                                    />{" "}
                                                                                                                    &nbsp;วอลเลท
                                                                                                                </div>
                                                                                                                <div className="text-center">
                                                                                                                    {trueaccount.name} :&nbsp; {
                                                                                                                        trueaccount.telnumber
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="text-center pt-2">
                                                                                                                    <a href={() => false} style={{ cursor: "pointer" }}
                                                                                                                        //  onClick={() => navigator.clipboard.writeText(trueaccount.telnumber)}
                                                                                                                        onClick={() => this.copyCode(trueaccount.telnumber)}
                                                                                                                    >
                                                                                                                        <div
                                                                                                                            className="btn v-login"
                                                                                                                            style={{
                                                                                                                                fontSize: 16,
                                                                                                                                cursor: "pointer"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            วอลลเท :&nbsp;{" "}
                                                                                                                            <span id="copy-banktransfer">
                                                                                                                                {trueaccount.telnumber}
                                                                                                                            </span>{" "}
                                                                                                                            &nbsp;&nbsp;
                                                                                                                            <span
                                                                                                                                className="badge text-bg-white p-1"
                                                                                                                                style={{
                                                                                                                                    borderRadius: "33.9916px"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                &nbsp; คัดลอก &nbsp;
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                : ""}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text-white-v1-deposit text-center">
                                                                <div className="box-content p-0 animated animatedFadeInUp fadeInUp mt-3">
                                                                    <div className="sub-content p-3">
                                                                        <div className="d-flex justify-content-between">
                                                                            <div className="text-white-v1-deposit pt-1">
                                                                                <img
                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                                                    alt=""
                                                                                    style={{ width: 30 }}
                                                                                />{" "}
                                                                                &nbsp; รับโปรโมชั่นอัตโนมัติ
                                                                            </div>
                                                                            <div className="text-white-v1-deposit">
                                                                                <div className="form-check form-switch">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        role="switch"
                                                                                        id="flexSwitchCheckDefault"
                                                                                        style={{ width: "6em", padding: 12 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/**/}
                                                            {/**/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div style={{ display: tabselect === 2 ? "block" : "none" }}
                            data-elementor-type="wp-page"
                            data-elementor-id={154}
                            className="elementor elementor-154"
                        >
                            <section className="-contact-wrapper row m-0">
                                <div className="-games-provider-wrapper pl-0 pr-0 mx-auto -menu-index-page-bg col-12">
                                    {/**/}
                                </div>
                                <div className="-games-index-page col-12">
                                    <div className="mb-2 text-white-v1">
                                        {/**/}
                                        <div className="padding-router-view">
                                            <div>
                                                <div className="row m-0 border-shadow-title" >
                                                    <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                                        <div className="text-center bg-title-web">
                                                            <div>
                                                                <div className="text-title text-white-v1 text-center">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="text-white-v1-deposit" href='/'>
                                                                            <a role="button" onClick={(e) => {
                                                                                this.checkurl(e, "/")
                                                                            }}>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={25}
                                                                                    height={25}
                                                                                    fill="currentColor"
                                                                                    className="bi bi-arrow-left-circle text-color-back"
                                                                                    viewBox="0 0 16 16"
                                                                                >
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                                                                    />
                                                                                </svg>
                                                                            </a>
                                                                        </div>
                                                                        <div className="text-white-v1-deposit">
                                                                            <h5>ถอนเงิน</h5>
                                                                        </div>
                                                                        <div>{/**/}</div>
                                                                    </div>
                                                                    <hr className="mt-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="x-login-index animated animatedFadeInUp fadeInUp">
                                                            <div>
                                                                <div className="pt-1 pb-1">
                                                                    <ul className="nav nav-tabs d-flex justify-content-center">
                                                                        <li className="nav-item text-center w-50">
                                                                            <a
                                                                                className="nav-link active"
                                                                                aria-current="page"
                                                                                href="#"
                                                                            >
                                                                                <img
                                                                                    className="img-bank-title"
                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7c0dc589-eb20-4725-98df-0531730fff00/public"
                                                                                    alt=""
                                                                                />{" "}
                                                                                ธนาคาร
                                                                            </a>
                                                                        </li>
                                                                        {/* <li className="nav-item text-center w-50">
                      <a className="nav-link" aria-current="page" href="#">
                        <img
                          className="img-bank-title"
                          src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a152f75f-0588-49aa-2836-4d9528f69000/public"
                          alt=""
                        />{" "}
                        ทรูมันนี่
                      </a>
                    </li> */}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="row m-0 border-shadow-title" >
                                                    <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                                        <div className="p-1 bg-deposit-button example animated animatedFadeInUp fadeInUp">
                                                            <div className="p-0 x-margin-top-v3">
                                                                <div className="border-shadow-title">
                                                                    <div>
                                                                        <div className="mt-2 text-white-v1">
                                                                            <div>
                                                                                <div>
                                                                                    <div>
                                                                                        <div>
                                                                                            <div>
                                                                                                <div className="text-white-v1">
                                                                                                    <div>
                                                                                                        <div className="mb-1">
                                                                                                            <label
                                                                                                                htmlFor="price"
                                                                                                                className="mt-1 mb-1 text-left"
                                                                                                            >
                                                                                                                จำนวนเงิน
                                                                                                            </label>
                                                                                                            <div className="-x-input-icon flex-column">
                                                                                                                <div className="input-group mb-1">
                                                                                                                    <input
                                                                                                                        id="withdraw_amount"
                                                                                                                        onChange={this.onChange}
                                                                                                                        name="withdrawamount"
                                                                                                                        value={this.state.withdrawamount}
                                                                                                                        className="form-control"
                                                                                                                        placeholder={0}
                                                                                                                        aria-label={0}
                                                                                                                        aria-describedby="basic-addon1"
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="mb-1">
                                                                                                            <label
                                                                                                                htmlFor="price"
                                                                                                                className="mt-1 mb-1 text-left"
                                                                                                            >
                                                                                                                เลือกจำนวน
                                                                                                            </label>
                                                                                                            <div className="mb-4 row m-0">
                                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 100)}>
                                                                                                                        <div className="text-white-v1">
                                                                                                                            100
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 300)}>
                                                                                                                        <div className="text-white-v1">
                                                                                                                            300
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 500)}>
                                                                                                                        <div className="text-white-v1">
                                                                                                                            500
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 1000)}>
                                                                                                                        <div className="text-white-v1">
                                                                                                                            1,000
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 3000)}>
                                                                                                                        <div className="text-white-v1">
                                                                                                                            3,000
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 5000)}>
                                                                                                                        <div className="text-white-v1">
                                                                                                                            5,000
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 10000)}>
                                                                                                                        <div className="text-white-v1">
                                                                                                                            10,000
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 30000)}>
                                                                                                                        <div className="text-white-v1">
                                                                                                                            30,000
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 50000)}>
                                                                                                                        <div className="text-white-v1">
                                                                                                                            50,000
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/**/}
                                                                                                    <div>
                                                                                                        <div className="mt-1 mb-4">
                                                                                                            <div>
                                                                                                                <button
                                                                                                                    onClick={(e) => this.submitwithdraw(e)}
                                                                                                                    className="btn -submit js-submit-accept-term v-login btn-success mt-lg-1 mt-0 w-100 p-2">
                                                                                                                    ถัดไป
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/**/}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/**/}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/**/}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/**/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                </div>
                <div className="w-100" style={{ display: tabselect == 0 ? "none" : "" }}>
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                    <div className="snow" />
                </div>
                <div className="marginsec01" style={{ display: tabselect == 0 ? "" : "none" }}>
                    <div className="swiper-container mySwiper swiper-initialized swiper-horizontal swiper-pointer-events">
                        <div
                            className="swiper-wrapper"
                            id="swiper-wrapper-d5a29c4dc400c887"
                            aria-live="off"
                            style={{
                                transitionDuration: "0ms",
                                transform: "translate3d(456.5px, 0px, 0px)"
                            }}
                        >
                            <div
                                className="swiper-slide swiper-slide-active"
                                role="group"
                                aria-label="1 / 5"
                                style={{ marginRight: 30 }}
                            >
                                <img
                                    width={1000}
                                    height={500}
                                    src="https://cdn.javisauto.live/image/e11f2d0a-1013-43ae-bb99-c7898b3c99c0.png"
                                    className="image wp-image-15  attachment-full size-full"
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                    decoding="async"
                                    srcSet="https://cdn.javisauto.live/image/e11f2d0a-1013-43ae-bb99-c7898b3c99c0.png 1000w, https://cdn.javisauto.live/image/e11f2d0a-1013-43ae-bb99-c7898b3c99c0.png 768w"
                                    sizes="(max-width: 1000px) 100vw, 1000px"
                                />
                            </div>
                            <div
                                className="swiper-slide swiper-slide-next"
                                role="group"
                                aria-label="2 / 5"
                                style={{ marginRight: 30 }}
                            >
                                <img
                                    width={1000}
                                    height={500}
                                    src="https://cdn.javisauto.live/image/d7ebba20-586f-494c-962a-9ef42392701a.png"
                                    className="image wp-image-16  attachment-full size-full"
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                    decoding="async"
                                    srcSet="https://cdn.javisauto.live/image/d7ebba20-586f-494c-962a-9ef42392701a.png 1000w, https://cdn.javisauto.live/image/d7ebba20-586f-494c-962a-9ef42392701a.png 768w"
                                    sizes="(max-width: 1000px) 100vw, 1000px"
                                />
                            </div>
                            <div
                                className="swiper-slide"
                                role="group"
                                aria-label="3 / 5"
                                style={{ marginRight: 30 }}
                            >
                                <img
                                    width={1000}
                                    height={500}
                                    src="https://cdn.javisauto.live/image/1cdb03b3-0188-4ea8-8318-51fdca673ded.png"
                                    className="image wp-image-17  attachment-full size-full"
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                    decoding="async"
                                    srcSet="https://cdn.javisauto.live/image/1cdb03b3-0188-4ea8-8318-51fdca673ded.png 300w, https://cdn.javisauto.live/image/1cdb03b3-0188-4ea8-8318-51fdca673ded.png 768w"
                                    sizes="(max-width: 1000px) 100vw, 1000px"
                                />
                            </div>
                        </div>
                        <span
                            className="swiper-notification"
                            aria-live="assertive"
                            aria-atomic="true"
                        />
                    </div>
                </div>
                <div className="container-lg" style={{ display: tabselect == 0 ? "" : "none" }}>
                    <div className="wrapper">
                        <div className="inwrapsec01"
                            role="button" onClick={(e) => {
                                this.checkurl(e, "/casino")
                            }}

                        >
                            <img
                                width={500}
                                height={200}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/5-1.png"
                                className="image wp-image-20  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/5-1.png 500w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/5-1-300x120.png 300w"
                                sizes="(max-width: 500px) 100vw, 500px"
                            />
                        </div>
                        <div className="inwrapsec01"
                            role="button" onClick={(e) => {
                                this.checkurl(e, "/slot")
                            }}

                        >
                            <img
                                width={500}
                                height={200}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/6-1.png"
                                className="image wp-image-21  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/6-1.png 500w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/6-1-300x120.png 300w"
                                sizes="(max-width: 500px) 100vw, 500px"
                            />
                        </div>
                        <div className="inwrapsec01" role="button" onClick={(e) => {
                            this.checkurl(e, "/lotto")
                        }}>
                            <img
                                width={500}
                                height={200}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/7-1.png"
                                className="image wp-image-22  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/7-1.png 500w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/7-1-300x120.png 300w"
                                sizes="(max-width: 500px) 100vw, 500px"
                            />
                        </div>
                        <div className="inwrapsec01"
                            role="button" onClick={(e) => {
                                this.checkurl(e, "/sport")
                            }}>
                            <img
                                width={500}
                                height={200}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/8-1.png"
                                className="image wp-image-23  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/8-1.png 500w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/8-1-300x120.png 300w"
                                sizes="(max-width: 500px) 100vw, 500px"
                            />
                        </div>{" "}
                    </div>
                </div>
                <div className="container-lg" style={{ display: tabselect == 0 ? "" : "none" }}>
                    <div className="bordersec05">
                        <div className="divhead02">
                            <div className="glowhead">
                                <img
                                    width={512}
                                    height={512}
                                    src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                                    className="image wp-image-40  attachment-full size-full"
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                    decoding="async"
                                    srcSet="/build/web/igame-index-lobby-wm/img/logo.png?v=1 512w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/cropped-logo-2-300x300.png 300w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/cropped-logo-2-150x150.png 150w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/cropped-logo-2-270x270.png 270w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/cropped-logo-2-192x192.png 192w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/cropped-logo-2-180x180.png 180w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/cropped-logo-2-32x32.png 32w"
                                    sizes="(max-width: 512px) 100vw, 512px"
                                />
                            </div>{" "}
                        </div>
                        <div className="row align-items-center mb-5">
                            <div className="col-md-6 px-4 position-relative">
                                <div className="buttonregister">
                                    <div className="registext">
                                        {" "}
                                        <div className="textwidget">เข้าสู่ระบบ</div>
                                    </div>{" "}
                                    <img
                                        className="imgglow"
                                        src="https://ideabet.live/theme/usa/wp-content/themes/redgold/images/btnregister3.png"
                                    />
                                    <div className="containdetail02">
                                        <span className="welcometext">Wellcome To SA</span>{" "}
                                        <div className="textwidget">
                                            <div className="textwidget">
                                                SA
                                                เว็บไซต์เดิมพันออนไลน์ที่รวมรวบคาสิโนและสล็อตที่มีมาตรฐานและมีคุณภาพไว้มากที่สุด
                                            </div>
                                        </div>
                                    </div>{" "}
                                </div>
                            </div>
                            <div className="col-md-6 px-5 mx-auto text-center position-relative">
                                <div className="animate01">
                                    <img
                                        width={776}
                                        height={858}
                                        src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/money-1-1.png"
                                        className="image wp-image-95  attachment-full size-full"
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                        decoding="async"
                                        srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/money-1-1.png 776w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/money-1-1-271x300.png 271w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/money-1-1-768x849.png 768w"
                                        sizes="(max-width: 776px) 100vw, 776px"
                                    />
                                </div>{" "}
                                <div className="animate02">
                                    <img
                                        width={768}
                                        height={588}
                                        src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/dicegold-768x588-1.png"
                                        className="image wp-image-94  attachment-full size-full"
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                        decoding="async"
                                        srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/dicegold-768x588-1.png 768w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/dicegold-768x588-1-300x230.png 300w"
                                        sizes="(max-width: 768px) 100vw, 768px"
                                    />
                                </div>{" "}
                                <div className="animate03">
                                    <img
                                        width={500}
                                        height={109}
                                        src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/poker-1.png"
                                        className="image wp-image-96  attachment-full size-full"
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                        decoding="async"
                                        srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/poker-1.png 500w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/poker-1-300x65.png 300w"
                                        sizes="(max-width: 500px) 100vw, 500px"
                                    />
                                </div>{" "}
                                <div className="girlsec03">
                                    <img
                                        width={476}
                                        height={559}
                                        src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/girlred-1.png"
                                        className="image wp-image-32  attachment-full size-full"
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                        decoding="async"
                                        srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/girlred-1.png 476w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/girlred-1-255x300.png 255w"
                                        sizes="(max-width: 476px) 100vw, 476px"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-lg" style={{ display: tabselect == 0 ? "" : "none" }}>
                    <div className="bordersec03">
                        <div className="headerimgsec04">
                            <img
                                width={676}
                                height={195}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/b-howtoplay-min2-1.png"
                                className="image wp-image-98  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/b-howtoplay-min2-1.png 676w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/b-howtoplay-min2-1-300x87.png 300w"
                                sizes="(max-width: 676px) 100vw, 676px"
                            />
                        </div>
                        <div className="headerimgsec042">
                            <img
                                width={1024}
                                height={64}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/casino-1-1024x64.png"
                                className="image wp-image-99  attachment-large size-large"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/casino-1-1024x64.png 1024w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/casino-1-300x19.png 300w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/casino-1-768x48.png 768w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/casino-1-1536x95.png 1536w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/casino-1.png 1673w"
                                sizes="(max-width: 1024px) 100vw, 1024px"
                            />
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <img
                                    width={330}
                                    height={295}
                                    src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/001.png"
                                    className="image wp-image-88  attachment-full size-full"
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                    decoding="async"
                                    srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/001.png 330w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/001-300x268.png 300w"
                                    sizes="(max-width: 330px) 100vw, 330px"
                                />
                            </div>
                            <div className="col-sm-4">
                                <img
                                    width={341}
                                    height={301}
                                    src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/002.png"
                                    className="image wp-image-89  attachment-full size-full"
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                    decoding="async"
                                    srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/002.png 341w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/002-300x265.png 300w"
                                    sizes="(max-width: 341px) 100vw, 341px"
                                />
                            </div>
                            <div className="col-sm-4">
                                <img
                                    width={349}
                                    height={297}
                                    src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/003.png"
                                    className="image wp-image-90  attachment-full size-full"
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                    decoding="async"
                                    srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/003.png 349w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/003-300x255.png 300w"
                                    sizes="(max-width: 349px) 100vw, 349px"
                                />
                            </div>{" "}
                        </div>
                        <div className="bottomimgsec03">
                            <img
                                width={1024}
                                height={183}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/casino2-1-1024x183.png"
                                className="image wp-image-28  attachment-large size-large"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/casino2-1-1024x183.png 1024w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/casino2-1-300x54.png 300w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/casino2-1-768x137.png 768w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/casino2-1.png 1183w"
                                sizes="(max-width: 1024px) 100vw, 1024px"
                            />
                        </div>
                    </div>
                </div>
                <div id="promotion" />
                <div className="togglebg01" style={{ display: tabselect == 0 ? "" : "none" }}>
                    <div className="toggleline01 linegolddiv" />
                    <div className="container-lg">
                        <div className="bordersec04">
                            <div className="container-lg containsec05">
                                <div className="headerpromotion">
                                    <img
                                        width={646}
                                        height={223}
                                        src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/poptext-1.png"
                                        className="image wp-image-100  attachment-full size-full"
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                        decoding="async"
                                        srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/poptext-1.png 646w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/poptext-1-300x104.png 300w"
                                        sizes="(max-width: 646px) 100vw, 646px"
                                    />
                                </div>
                                <div className="swiper-container promotionsl swiper-initialized swiper-horizontal swiper-pointer-events">
                                    <div
                                        className="swiper-wrapper"
                                        id="swiper-wrapper-aa4991032e881c6ec"
                                        aria-live="off"
                                        style={{
                                            cursor: "grab",
                                            transitionDuration: "0ms",
                                            transform: "translate3d(380px, 0px, 0px)"
                                        }}
                                    >
                                        <div
                                            className="swiper-slide swiper-slide-active"
                                            role="group"
                                            aria-label="1 /6"
                                        >
                                            <img
                                                width={1000}
                                                height={1000}
                                                src="/build/web/igame-index-lobby-wm/img/123.png"
                                                className="image wp-image-92  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                                sizes="(max-width: 1000px) 100vw, 1000px"
                                            />
                                        </div>
                                        <div
                                            className="swiper-slide swiper-slide-active"
                                            role="group"
                                            aria-label="2 / 6"
                                        >
                                            <img
                                                width={1000}
                                                height={1000}
                                                src="/build/web/igame-index-lobby-wm/img/124.png"
                                                className="image wp-image-92  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                                sizes="(max-width: 1000px) 100vw, 1000px"
                                            />
                                        </div>
                                        <div
                                            className="swiper-slide swiper-slide-active"
                                            role="group"
                                            aria-label="3 / 6"
                                        >
                                            <img
                                                width={1000}
                                                height={1000}
                                                src="/build/web/igame-index-lobby-wm/img/125.png"
                                                className="image wp-image-92  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                                sizes="(max-width: 1000px) 100vw, 1000px"
                                            />
                                        </div>
                                        <div
                                            className="swiper-slide swiper-slide-active"
                                            role="group"
                                            aria-label="4 / 6"
                                        >
                                            <img
                                                width={1000}
                                                height={1000}
                                                src="/build/web/igame-index-lobby-wm/img/126.png"
                                                className="image wp-image-92  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                                sizes="(max-width: 1000px) 100vw, 1000px"
                                            />
                                        </div>
                                        <div
                                            className="swiper-slide swiper-slide-active"
                                            role="group"
                                            aria-label="5 / 6"
                                        >
                                            <img
                                                width={1000}
                                                height={1000}
                                                src="/build/web/igame-index-lobby-wm/img/127.png"
                                                className="image wp-image-92  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                                sizes="(max-width: 1000px) 100vw, 1000px"
                                            />
                                        </div>
                                        <div
                                            className="swiper-slide swiper-slide-active"
                                            role="group"
                                            aria-label="6 / 6"
                                        >
                                            <img
                                                width={1000}
                                                height={1000}
                                                src="/build/web/igame-index-lobby-wm/img/128.png"
                                                className="image wp-image-92  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                                sizes="(max-width: 1000px) 100vw, 1000px"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="swiper-button-next swiper-button-white"
                                        tabIndex={0}
                                        role="button"
                                        aria-label="Next slide"
                                        aria-controls="swiper-wrapper-aa4991032e881c6ec"
                                        aria-disabled="false"
                                    />
                                    <div
                                        className="swiper-button-prev swiper-button-white swiper-button-disabled"
                                        tabIndex={-1}
                                        role="button"
                                        aria-label="Previous slide"
                                        aria-controls="swiper-wrapper-aa4991032e881c6ec"
                                        aria-disabled="true"
                                    />
                                    <span
                                        className="swiper-notification"
                                        aria-live="assertive"
                                        aria-atomic="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-lg">
                        <div className="py-5 bordersec02">
                            <div className="logosec06">
                                <img src="/build/web/igame-index-lobby-wm/img/logo.png?v=1" />
                            </div>
                            <div className="w-100 text-center position-relative">
                                <div className="avatar">
                                    <div className="imganimation">
                                        <img
                                            width={1655}
                                            height={505}
                                            src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/pig3-1.png"
                                            className="image wp-image-102  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            decoding="async"
                                            srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/pig3-1.png 1655w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/pig3-1-300x92.png 300w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/pig3-1-1024x312.png 1024w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/pig3-1-768x234.png 768w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/pig3-1-1536x469.png 1536w"
                                            sizes="(max-width: 1655px) 100vw, 1655px"
                                        />
                                    </div>{" "}
                                </div>
                                <div className="imganimation">
                                    <img
                                        width={1024}
                                        height={624}
                                        src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/pig2-1-1024x624.png"
                                        className="image wp-image-101  attachment-large size-large"
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                        decoding="async"
                                        srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/pig2-1-1024x624.png 1024w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/pig2-1-300x183.png 300w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/pig2-1-768x468.png 768w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/pig2-1-1536x936.png 1536w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/pig2-1.png 1655w"
                                        sizes="(max-width: 1024px) 100vw, 1024px"
                                    />
                                </div>{" "}
                            </div>
                            <div className="mt-4"></div>
                        </div>
                    </div>
                    <div className="cardbottom">
                        <div className="container">
                            <div className="hideservice01">
                                <div className="row" style={{ marginTop: 20 }}>
                                    <div className="col-6 col-lg-3 relabottom">
                                        <div className="iconbottom">
                                            <img
                                                width={160}
                                                height={160}
                                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab-index-bottom-icon-1-1.png"
                                                className="image wp-image-34  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                                loading="lazy"
                                                srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab-index-bottom-icon-1-1.png 160w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab-index-bottom-icon-1-1-150x150.png 150w"
                                                sizes="(max-width: 160px) 100vw, 160px"
                                            />
                                        </div>
                                        <div className="cardbottom">
                                            <img
                                                src="https://ideabet.live/theme/usa/wp-content/themes/redgold/images/tapbottomred.png"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="textcenterbottom">
                                            {" "}
                                            <div className="texttopbottom">
                                                <span>เจ้าแรกในไทย</span>
                                            </div>{" "}
                                            <div className="textwidget">
                                                ผู้นำ เทคโนโลยีจำลองลุ้นไพ่เสมือนอยู่ในคาสิโนจริงๆ
                                            </div>
                                        </div>{" "}
                                    </div>
                                    <div className="col-6 col-lg-3 relabottom">
                                        <div className="iconbottom">
                                            <img
                                                width={160}
                                                height={160}
                                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab-index-bottom-icon-2-1.png"
                                                className="image wp-image-35  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                                loading="lazy"
                                                srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab-index-bottom-icon-2-1.png 160w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab-index-bottom-icon-2-1-150x150.png 150w"
                                                sizes="(max-width: 160px) 100vw, 160px"
                                            />
                                        </div>
                                        <div className="cardbottom">
                                            <img
                                                src="https://ideabet.live/theme/usa/wp-content/themes/redgold/images/tapbottomred.png"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="textcenterbottom">
                                            {" "}
                                            <div className="texttopbottom">
                                                <span>คาสิโน ที่เชื่อถือได้</span>
                                            </div>{" "}
                                            <div className="textwidget">
                                                ดูย้อนหลังการเล่น ได้ทุกเกมส์แฟร์ที่สุด
                                            </div>
                                        </div>{" "}
                                    </div>
                                    <div className="col-6 col-lg-3 relabottom">
                                        <div className="iconbottom">
                                            <img
                                                width={160}
                                                height={160}
                                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab-index-bottom-icon-3-1.png"
                                                className="image wp-image-36  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                                loading="lazy"
                                                srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab-index-bottom-icon-3-1.png 160w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab-index-bottom-icon-3-1-150x150.png 150w"
                                                sizes="(max-width: 160px) 100vw, 160px"
                                            />
                                        </div>
                                        <div className="cardbottom">
                                            <img
                                                src="https://ideabet.live/theme/usa/wp-content/themes/redgold/images/tapbottomred.png"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="textcenterbottom">
                                            {" "}
                                            <div className="texttopbottom">
                                                <span>เสถียร์ ใช้ได้จริง</span>
                                            </div>{" "}
                                            <div className="textwidget">
                                                ฝาก ถอน ออโต้ง่าย รวดเร็ว ไม่งอแง
                                            </div>
                                        </div>{" "}
                                    </div>
                                    <div className="col-6 col-lg-3 relabottom">
                                        <div className="iconbottom">
                                            <img
                                                width={160}
                                                height={160}
                                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab-index-bottom-icon-4-1.png"
                                                className="image wp-image-37  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                                loading="lazy"
                                                srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab-index-bottom-icon-4-1.png 160w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab-index-bottom-icon-4-1-150x150.png 150w"
                                                sizes="(max-width: 160px) 100vw, 160px"
                                            />
                                        </div>
                                        <div className="cardbottom">
                                            <img
                                                src="https://ideabet.live/theme/usa/wp-content/themes/redgold/images/tapbottomred.png"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="textcenterbottom">
                                            {" "}
                                            <div className="texttopbottom">
                                                <span>สะดวกทุกที่ ทุกเวลา</span>
                                            </div>{" "}
                                            <div className="textwidget">
                                                ผ่านมาตราฐาน แอพมือถือเล่นได้ทั้ง iOS, Android
                                            </div>
                                        </div>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="containpost" style={{ display: tabselect == 0 ? "" : "none" }}>
                    <div className="prohead2">
                        {" "}
                        <div className="textwidget">บทความ</div>
                    </div>
                    <div className="swiper-container mt-4 postslide swiper-coverflow swiper-3d swiper-initialized swiper-horizontal swiper-pointer-events">
                        <div
                            className="swiper-wrapper"
                            id="swiper-wrapper-8cb7883ab944fdad"
                            aria-live="off"
                            style={{
                                cursor: "grab",
                                transitionDuration: "0ms",
                                transform: "translate3d(-618.5px, 0px, 0px)"
                            }}
                        >
                            <div
                                className="swiper-slide"
                                onClick="location.href='https://ideabet.live/theme/usa/2021/11/15/%e0%b8%97%e0%b8%99%e0%b8%a3%e0%b8%9c%e0%b8%9b%e0%b9%81%e0%b9%88%e0%b8%9f%e0%b8%94%e0%b8%95%e0%b8%84%e0%b9%88%e0%b8%95%e0%b8%84%e0%b8%9e%e0%b9%86%e0%b9%84%e0%b8%9e/'"
                                role="group"
                                aria-label="1 / 5"
                                style={{
                                    transitionDuration: "0ms",
                                    transform:
                                        "translate3d(0px, 0px, -296.667px) rotateX(0deg) rotateY(59.3333deg) scale(1)",
                                    zIndex: -2
                                }}
                            >
                                <img
                                    width={1000}
                                    height={546}
                                    src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/batch_img202004201030369-1.jpg"
                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/batch_img202004201030369-1.jpg 1000w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/batch_img202004201030369-1-300x164.jpg 300w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/batch_img202004201030369-1-768x419.jpg 768w"
                                    sizes="(max-width: 1000px) 100vw, 1000px"
                                />{" "}
                                <div className="headerpostsl">ทนรผปแ่ฟดตค่ตคพๆไพ</div>
                                <div
                                    className="swiper-slide-shadow-left"
                                    style={{ opacity: "2.96667", transitionDuration: "0ms" }}
                                />
                                <div
                                    className="swiper-slide-shadow-right"
                                    style={{ opacity: 0, transitionDuration: "0ms" }}
                                />
                            </div>
                            <div
                                className="swiper-slide swiper-slide-visible"
                                onClick="location.href='https://ideabet.live/theme/usa/2021/11/15/%e0%b8%99%e0%b8%a3%e0%b9%86%e0%b9%84%e0%b8%99%e0%b8%a3%e0%b9%86%e0%b9%84%e0%b8%94%e0%b8%97%e0%b8%99%e0%b8%a3%e0%b9%86%e0%b9%84%e0%b8%94%e0%b8%9f%e0%b8%ab%e0%b8%94%e0%b8%ab%e0%b8%9f%e0%b8%94/'"
                                role="group"
                                aria-label="2 / 5"
                                style={{
                                    transitionDuration: "0ms",
                                    transform:
                                        "translate3d(0px, 0px, -196.667px) rotateX(0deg) rotateY(39.3333deg) scale(1)",
                                    zIndex: -1
                                }}
                            >
                                <img
                                    width={800}
                                    height={461}
                                    src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/e2bd7380c5cbcd889dea8d38d9738dd8-1.gif"
                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                />{" "}
                                <div className="headerpostsl">นรๆไนรๆไดทนรๆไดฟหดหฟด</div>
                                <div
                                    className="swiper-slide-shadow-left"
                                    style={{ opacity: "1.96667", transitionDuration: "0ms" }}
                                />
                                <div
                                    className="swiper-slide-shadow-right"
                                    style={{ opacity: 0, transitionDuration: "0ms" }}
                                />
                            </div>
                            <div
                                className="swiper-slide swiper-slide-visible swiper-slide-prev"
                                onClick="location.href='https://ideabet.live/theme/usa/2021/11/15/%e0%b8%aa%e0%b8%a7%e0%b8%b1%e0%b8%aa%e0%b8%94%e0%b8%b5%e0%b8%aa%e0%b8%a7%e0%b8%b1%e0%b8%aa%e0%b8%94%e0%b8%b5/'"
                                role="group"
                                aria-label="3 / 5"
                                style={{
                                    transitionDuration: "0ms",
                                    transform:
                                        "translate3d(0px, 0px, -96.6667px) rotateX(0deg) rotateY(19.3333deg) scale(1)",
                                    zIndex: 0
                                }}
                            >
                                <img
                                    width={1920}
                                    height={1080}
                                    src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/6cdf2a984fc6afda37fa0a9fe4f7c652-1.jpg"
                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/6cdf2a984fc6afda37fa0a9fe4f7c652-1.jpg 1920w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/6cdf2a984fc6afda37fa0a9fe4f7c652-1-300x169.jpg 300w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/6cdf2a984fc6afda37fa0a9fe4f7c652-1-1024x576.jpg 1024w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/6cdf2a984fc6afda37fa0a9fe4f7c652-1-768x432.jpg 768w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/6cdf2a984fc6afda37fa0a9fe4f7c652-1-1536x864.jpg 1536w"
                                    sizes="(max-width: 1920px) 100vw, 1920px"
                                />{" "}
                                <div className="headerpostsl">
                                    สวัสดีสวัสดีฟหกืฟหรีกีๆไๆรีไผดคตดตคๆพไพๆไพฟหด
                                </div>
                                <div
                                    className="swiper-slide-shadow-left"
                                    style={{ opacity: "0.966667", transitionDuration: "0ms" }}
                                />
                                <div
                                    className="swiper-slide-shadow-right"
                                    style={{ opacity: 0, transitionDuration: "0ms" }}
                                />
                            </div>
                            <div
                                className="swiper-slide swiper-slide-visible swiper-slide-active"
                                onClick="location.href='https://ideabet.live/theme/usa/2021/11/15/%e0%b8%aa%e0%b8%a7%e0%b8%b1%e0%b8%aa%e0%b8%94%e0%b8%b5%e0%b8%aa%e0%b8%a7%e0%b8%b1%e0%b8%aa%e0%b8%94%e0%b8%b5%e0%b8%aa%e0%b8%a7%e0%b8%b1%e0%b8%aa%e0%b8%94%e0%b8%b5%e0%b8%aa%e0%b8%a7%e0%b8%b1%e0%b8%aa/'"
                                role="group"
                                aria-label="4 / 5"
                                style={{
                                    transitionDuration: "0ms",
                                    transform:
                                        "translate3d(0px, 0px, -3.33333px) rotateX(0deg) rotateY(-0.666667deg) scale(1)",
                                    zIndex: 1
                                }}
                            >
                                <img
                                    width={1000}
                                    height={546}
                                    src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/batch_img20200420074597-1.jpg"
                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/batch_img20200420074597-1.jpg 1000w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/batch_img20200420074597-1-300x164.jpg 300w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/batch_img20200420074597-1-768x419.jpg 768w"
                                    sizes="(max-width: 1000px) 100vw, 1000px"
                                />{" "}
                                <div className="headerpostsl">
                                    สวัสดีสวัสดีสวัสดีสวัสดีสวัสดีสวัสดีสวัสดี
                                </div>
                                <div
                                    className="swiper-slide-shadow-left"
                                    style={{ opacity: 0, transitionDuration: "0ms" }}
                                />
                                <div
                                    className="swiper-slide-shadow-right"
                                    style={{ opacity: "0.0333333", transitionDuration: "0ms" }}
                                />
                            </div>
                            <div
                                className="swiper-slide swiper-slide-visible swiper-slide-next"
                                onClick="location.href='https://ideabet.live/theme/usa/2021/11/15/lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-lorem/'"
                                role="group"
                                aria-label="5 / 5"
                                style={{
                                    transitionDuration: "0ms",
                                    transform:
                                        "translate3d(0px, 0px, -103.333px) rotateX(0deg) rotateY(-20.6667deg) scale(1)",
                                    zIndex: 0
                                }}
                            >
                                <img
                                    width={1920}
                                    height={1080}
                                    src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/2825826-1.gif"
                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                />{" "}
                                <div className="headerpostsl">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem
                                </div>
                                <div
                                    className="swiper-slide-shadow-left"
                                    style={{ opacity: 0, transitionDuration: "0ms" }}
                                />
                                <div
                                    className="swiper-slide-shadow-right"
                                    style={{ opacity: "1.03333", transitionDuration: "0ms" }}
                                />
                            </div>
                        </div>
                        <div className="swiper-pagination2 swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
                            <span
                                className="swiper-pagination-bullet"
                                tabIndex={0}
                                role="button"
                                aria-label="Go to slide 1"
                            />
                            <span
                                className="swiper-pagination-bullet"
                                tabIndex={0}
                                role="button"
                                aria-label="Go to slide 2"
                            />
                            <span
                                className="swiper-pagination-bullet"
                                tabIndex={0}
                                role="button"
                                aria-label="Go to slide 3"
                            />
                            <span
                                className="swiper-pagination-bullet swiper-pagination-bullet-active"
                                tabIndex={0}
                                role="button"
                                aria-label="Go to slide 4"
                                aria-current="true"
                            />
                            <span
                                className="swiper-pagination-bullet"
                                tabIndex={0}
                                role="button"
                                aria-label="Go to slide 5"
                            />
                        </div>
                        <span
                            className="swiper-notification"
                            aria-live="assertive"
                            aria-atomic="true"
                        />
                    </div>
                    <div className="text-center mt-3">
                        <button className="btn allpost">
                            {" "}
                            <div className="textwidget">
                                <a href="/category/post/">บทความทั้งหมด</a>
                            </div>
                        </button>
                    </div>
                </div>
                <footer className="footer mt-auto " >
                    <div className="containbankimg">
                        <div className="inimgbank">
                            <img
                                width={500}
                                height={500}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/kbank.svg"
                                className="image wp-image-107  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                loading="lazy"
                            />
                        </div>
                        <div className="inimgbank">
                            <img
                                width={500}
                                height={500}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/krungsri.svg"
                                className="image wp-image-108  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                loading="lazy"
                            />
                        </div>
                        <div className="inimgbank">
                            <img
                                width={500}
                                height={500}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/scb.svg"
                                className="image wp-image-110  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                loading="lazy"
                            />
                        </div>
                        <div className="inimgbank">
                            <img
                                width={500}
                                height={500}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/krungthai.svg"
                                className="image wp-image-109  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                loading="lazy"
                            />
                        </div>
                        <div className="inimgbank">
                            <img
                                width={500}
                                height={500}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/cimb.svg"
                                className="image wp-image-106  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                loading="lazy"
                            />
                        </div>
                        <div className="inimgbank">
                            <img
                                width={500}
                                height={500}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/bangkok.svg"
                                className="image wp-image-105  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                loading="lazy"
                            />
                        </div>
                        <div className="inimgbank">
                            <img
                                width={500}
                                height={500}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tmb.svg"
                                className="image wp-image-111  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                loading="lazy"
                            />
                        </div>
                        <div className="inimgbank">
                            <img
                                width={500}
                                height={500}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/aomsin.svg"
                                className="image wp-image-104  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                loading="lazy"
                            />
                        </div>
                        <div className="inimgbank">
                            <img
                                width={500}
                                height={500}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tnc.svg"
                                className="image wp-image-112  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                loading="lazy"
                            />
                        </div>
                        <div className="inimgbank">
                            <img
                                width={500}
                                height={500}
                                src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/uob.svg"
                                className="image wp-image-114  attachment-full size-full"
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                                decoding="async"
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <div>
                        <p>Term and condition</p>{" "}
                        <div className="textwidget">
                            <div className="textwidget">Copyright © 2021 All Rights Reserved.</div>
                        </div>
                    </div>
                    <div className="d-sm-block d-md-none" style={{ height: 75 }} />
                </footer>
                <div className="rectangle fixed-bottom" style={{ zIndex: 9 }}>
                    <div className="linefooter" />
                    <table className="tablefooter" align="center">
                        <tbody>
                            <tr>
                                <td className="tdfooter" style={{ display: loginstate ? "none" : "" }}>
                                <a role='button' onClick={(e) => {
                                        this.checkurl(e, "/")
                                    }}>
                                    <h2>หน้าหลัก</h2>
                                
                                        <img
                                            width={192}
                                            height={211}
                                            src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/unnamed-1-1.png"
                                            className="image wp-image-124  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            decoding="async"
                                            loa
                                            ding="lazy"
                                        />
                                    </a>
                                </td>
                                <td className="tdfooter" style={{ display: loginstate ? "" : "none" }}>
                                <a role='button' onClick={(e) => {
                                        this.checkurl(e, "/deposit")
                                    }}>
                                    <h2>ฝากเงิน</h2>
                                 
                                        <img
                                            width={50}
                                            height={50}
                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6117f3fd-7c08-4b4a-4a80-a5337a750d00/public"
                                            className="image wp-image-121  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            decoding="async"
                                            loading="lazy"
                                        />
                                    </a>
                                </td>
                                <td className="tdfooter" style={{ display: loginstate ? "none" : "" }}>
                                    <a role='button' onClick={(e) => {
                                        this.checkurl(e, "/signin")
                                    }}>
                                        <h2>เข้าสู่ระบบ</h2>

                                        <img
                                            width={50}
                                            height={50}
                                            src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/ic-menu-register-1.png"
                                            className="image wp-image-121  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            decoding="async"
                                            loading="lazy"
                                        />
                                    </a>
                                </td>
                                <td className="tdfooter" style={{ display: loginstate ? "" : "none" }}>
                                    <h2>ถอนเงิน</h2>
                                    <a role='button' onClick={(e) => {
                                        this.checkurl(e, "/withdraw")
                                    }}>
                                        <img
                                            width={50}
                                            height={50}
                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/42eee66a-d45e-4b22-fa0f-03e3b7046400/public"
                                            className="image wp-image-121  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            decoding="async"
                                            loading="lazy"
                                        />
                                    </a>
                                </td>
                                {" "}
                                <td className="tdfootercn">
                                    <a role='button' onClick={(e) => {
                                        this.checkurl(e, "/slot")
                                    }}>
                                        <div className="circle01">
                                            <img
                                              //  width={300}
                                               // height={300}
                                                src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                                                className="image wp-image-120  attachment-medium size-medium"
                                                alt=""
                                                style={{ width: "150px", height: "auto" }}
                                                decoding="async"
                                                loading="lazy"
                                                // srcSet="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/cropped-logo-1-300x300.png 300w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/cropped-logo-1-150x150.png 150w, https://ideabet.live/theme/usa/wp-content/uploads/2021/11/cropped-logo-1.png 512w"
                                               // sizes="(max-width: 300px) 100vw, 300px"
                                            />
                                        </div>{" "}
                                        <div className="pt-4">
                                            {" "}
                                            <div className="textwidget">
                                                เล่นเกมส์
                                            </div>
                                        </div>{" "}
                                    </a>
                                </td>
                                <td className="tdfooter">
                                <a role='button' onClick={(e) => {
                                        this.checkurl(e, "/promotion")
                                    }}>
                                    <h2>โปรโมชั่น</h2>
                                
                                        <img
                                            width={150}
                                            height={150}
                                            src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/tab_promotion-1.png"
                                            className="image wp-image-123  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            decoding="async"
                                            loading="lazy"
                                        />
                                    </a>
                                </td>
                                <td className="tdfooter">
                                <a href={this.state.linelink}>

                                    <h2>ติดต่อเรา</h2>
                                        <img
                                            width={146}
                                            height={163}
                                            src="https://ideabet.live/theme/usa/wp-content/uploads/2021/11/support-mobile-1.png"
                                            className="image wp-image-122  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            decoding="async"
                                            loading="lazy"
                                        />
                                    </a>
                                </td>{" "}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="overlay" />
                <div className="base-modal-overlay x-modal modal -v2 x-theme-switcher-v2 show animated animatedFadeInUp fadeInUp"
                    // style={{ display: this.state.modal ? "" : "none" }}
                    data-v-8bc7c3b9=""
                >
                    <div
                        className="base-modal pt-0 pb-0 w-100"
                        data-v-8bc7c3b9=""
                        style={{ margin: 10, textAlign: "-webkit-center" }}
                    >
                        <div
                            className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable modal-dialog-centered w-100 m-0"
                            data-v-8bc7c3b9=""
                            style={{ height: "auto" }}
                        >
                            <div
                                className="modal-content -modal-content text-white-v1"
                                data-v-8bc7c3b9=""
                            >
                                <a
                                    role="button"
                                    className="close-model-head mt-4 mb-4"
                                    data-v-8bc7c3b9=""
                                    onClick={(e) => {
                                        this.setState({
                                            modal: false
                                        });
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={30}
                                        height={30}
                                        fill="currentColor"
                                        className="bi bi-x-circle-fill"
                                        viewBox="0 0 16 16"
                                        data-v-8bc7c3b9=""
                                    >
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                                            data-v-8bc7c3b9=""
                                        />
                                    </svg>
                                </a>
                                {/**/}
                                <div
                                    className="pt-3 pb-4 x-theme-switcher-v2 text-center mt-3"
                                    data-v-8bc7c3b9=""
                                >
                                    <div className="-inner-header-section" data-v-8bc7c3b9="">
                                        <img
                                            src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                                            alt=""
                                            data-v-8bc7c3b9=""
                                            style={{ width: 160 }}
                                        />
                                        <div className="mt-1 mb-1 w-100" data-v-8bc7c3b9="" style={{ marginTop: "-30px" }}>
                                            <h3 className="title-footer-wrap pt-2">
                                                <b>Language / ภาษา </b>
                                            </h3>
                                            <ul className="logo-list-footer">
                                                <li role="button">
                                                    <img
                                                        className="lang-img active-lang"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e1626da-cc1f-451f-838f-54e5e1848f00/public"
                                                        alt=""
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        className="row -inner-center-body-section m-0"
                                        style={{ color: "white" }}
                                        data-v-8bc7c3b9=""
                                    >
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/profile")
                                                }}
                                                role="button"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3066ed62-07f3-4623-b4c0-ac1d1eb7e300/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3066ed62-07f3-4623-b4c0-ac1d1eb7e300/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3066ed62-07f3-4623-b4c0-ac1d1eb7e300/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3066ed62-07f3-4623-b4c0-ac1d1eb7e300/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3066ed62-07f3-4623-b4c0-ac1d1eb7e300/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3066ed62-07f3-4623-b4c0-ac1d1eb7e300/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        <span data-v-8bc7c3b9="" >ข้อมูลบัญชี</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/loss")
                                                }}
                                                role="button"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5ab912d5-9d59-4e39-f3fa-f108d3d62500/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5ab912d5-9d59-4e39-f3fa-f108d3d62500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5ab912d5-9d59-4e39-f3fa-f108d3d62500/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5ab912d5-9d59-4e39-f3fa-f108d3d62500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5ab912d5-9d59-4e39-f3fa-f108d3d62500/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5ab912d5-9d59-4e39-f3fa-f108d3d62500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        <span data-v-8bc7c3b9="">คืนยอดเสีย</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/refer/overview")
                                                }}
                                                role="button"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8497de75-3140-4e19-9747-a03adb7c7700/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8497de75-3140-4e19-9747-a03adb7c7700/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8497de75-3140-4e19-9747-a03adb7c7700/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8497de75-3140-4e19-9747-a03adb7c7700/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8497de75-3140-4e19-9747-a03adb7c7700/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8497de75-3140-4e19-9747-a03adb7c7700/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        <span data-v-8bc7c3b9="">เเนะนำเพื่อน</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/statement")
                                                }}
                                                role="button"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a2075ee6-ea48-47ed-726b-01a8b6ec8000/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a2075ee6-ea48-47ed-726b-01a8b6ec8000/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a2075ee6-ea48-47ed-726b-01a8b6ec8000/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a2075ee6-ea48-47ed-726b-01a8b6ec8000/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a2075ee6-ea48-47ed-726b-01a8b6ec8000/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a2075ee6-ea48-47ed-726b-01a8b6ec8000/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        <span data-v-8bc7c3b9="">ประวัติ</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/promotion")
                                                }}
                                                role="button"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        <span data-v-8bc7c3b9="">โปรโมชั่น</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        {/* <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                      <a
                       href='/promotion'
                        role="button"
                        className="btn -btn-item -event-button -menu-center -horizontal"
                        data-v-8bc7c3b9=""
                      >
                        <picture data-v-8bc7c3b9="">
                          <source
                            type="image/webp"
                            data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6311559f-81fe-484a-ea3e-30b9d40fb700/public"
                            srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6311559f-81fe-484a-ea3e-30b9d40fb700/public"
                            data-v-8bc7c3b9=""
                          />
                          <source
                            type="image/png?v=1"
                            data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6311559f-81fe-484a-ea3e-30b9d40fb700/public"
                            srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6311559f-81fe-484a-ea3e-30b9d40fb700/public"
                            data-v-8bc7c3b9=""
                          />
                          <img
                            className="img-fluid -icon-image ls-is-cached lazyloaded"
                            data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6311559f-81fe-484a-ea3e-30b9d40fb700/public"
                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6311559f-81fe-484a-ea3e-30b9d40fb700/public"
                            data-v-8bc7c3b9=""
                          />
                        </picture>
                        <div className="-typo-wrapper" data-v-8bc7c3b9="">
                          <div className="-typo -is-logged" data-v-8bc7c3b9="">
                            <span data-v-8bc7c3b9="">เเลกของรางวัล</span>
                          </div>
                        </div>
                      </a>
                    </div> */}
                                        {/**/}
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                href="/logout"
                                                role="button"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/630a8cf7-f7a8-4ff3-0e58-0b9929ce5500/public"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/630a8cf7-f7a8-4ff3-0e58-0b9929ce5500/public
                "
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/630a8cf7-f7a8-4ff3-0e58-0b9929ce5500/public"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/630a8cf7-f7a8-4ff3-0e58-0b9929ce5500/public
                "
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/630a8cf7-f7a8-4ff3-0e58-0b9929ce5500/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/630a8cf7-f7a8-4ff3-0e58-0b9929ce5500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        ออกจากระบบ
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/**/}
                </div>
                <div className="base-modal-overlay x-modal modal -v2 x-theme-switcher-v2 show animated animatedFadeInUp fadeInUp"
                    // style={{ display: this.state.modalx ? "" : "none" }}
                    data-v-8bc7c3b9=""
                >
                    <div
                        className="base-modal pt-0 pb-0 w-100"
                        data-v-8bc7c3b9=""
                        style={{ margin: 10, textAlign: "-webkit-center" }}
                    >
                        <div
                            className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable modal-dialog-centered w-100 m-0"
                            data-v-8bc7c3b9=""
                            style={{ height: "auto" }}
                        >


                            <div
                                className="modal-content -modal-content text-white-v1"
                                data-v-8bc7c3b9=""
                            >
                                <a
                                    onClick={(e) => {
                                        this.setState({
                                            modalx: false
                                        });
                                    }}
                                    role="button"
                                    className="close-model-head mt-4 mb-4"
                                    data-v-8bc7c3b9=""
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={30}
                                        height={30}
                                        fill="currentColor"
                                        className="bi bi-x-circle-fill"
                                        viewBox="0 0 16 16"
                                        data-v-8bc7c3b9=""
                                    >
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                                            data-v-8bc7c3b9=""
                                        />
                                    </svg>
                                </a>


                                <div className="pt-3 pb-4 x-theme-switcher-v2 text-center mt-3"
                                    data-v-8bc7c3b9=""
                                >
                                    <div className="-inner-header-section" data-v-8bc7c3b9="">
                                        <img
                                            src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                                            alt=""
                                            data-v-8bc7c3b9=""
                                            style={{ width: 160 }}
                                        />
                                    </div>
                                    {(loginstate != null) ? (!loginstate) ?
                                        <div className="row -inner-top-body-section" data-v-8bc7c3b9="">
                                            <div
                                                className="col-6 -wrapper-box"
                                                data-v-8bc7c3b9=""
                                                style={{ paddingLeft: 0 }}
                                            >
                                                <a
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/register")
                                                    }}
                                                    type="button"
                                                    className="btn -btn-item x-transaction-button-v2 -deposit -top-btn -horizontal"
                                                    data-v-8bc7c3b9=""
                                                >
                                                    <img
                                                        alt="img webรูปไอคอนฝากเงิน"
                                                        className="img-fluid -icon-image"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/279056cb-32d6-4c6d-7291-44366ffa1500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                        <div className="-title" data-v-8bc7c3b9="">
                                                            สมัครสมาชิก
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div
                                                className="col-6 -wrapper-box"
                                                data-v-8bc7c3b9=""
                                                style={{ paddingRight: 0 }}
                                            >
                                                <a
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/signin")
                                                    }}
                                                    type="button"
                                                    className="btn -btn-item x-transaction-button-v2 -withdraw -top-btn -horizontal"

                                                    data-v-8bc7c3b9=""
                                                >
                                                    <img
                                                        alt=" รูปไอคอนถอนเงิน"
                                                        className="img-fluid -icon-image"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/363edcc3-13dc-460b-671d-6a37e3191e00/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                        <div className="-title" data-v-8bc7c3b9="">
                                                            เข้าสู่ระบบ
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> :
                                        <div className="row -inner-top-body-section" data-v-8bc7c3b9="">
                                            <div
                                                className="col-6 -wrapper-box"
                                                data-v-8bc7c3b9=""
                                                style={{ paddingLeft: 0 }}
                                            >
                                                <a
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/deposit")
                                                    }}
                                                    role="button"
                                                    className="btn -btn-item x-transaction-button-v2 -deposit -top-btn -horizontal"
                                                    data-v-8bc7c3b9=""
                                                >
                                                    <img
                                                        className="img-fluid -icon-image"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d2a5801c-d49e-4e57-ee32-bcb42682e400/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                        <div className="-title" data-v-8bc7c3b9="">
                                                            ฝากเงิน
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div
                                                className="col-6 -wrapper-box"
                                                data-v-8bc7c3b9=""
                                                style={{ paddingRight: 0 }}
                                            >
                                                <a
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/withdraw")
                                                    }}
                                                    type="button"
                                                    className="btn -btn-item x-transaction-button-v2 -withdraw -top-btn -horizontal"
                                                    data-v-8bc7c3b9=""
                                                >
                                                    <img
                                                        className="img-fluid -icon-image"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6829da4a-a4a5-4f8e-2ce0-286825809600/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                        <div className="-title" data-v-8bc7c3b9="">
                                                            ถอนเงิน
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> : ""}
                                    <div
                                        className="row -inner-center-body-section m-0"
                                        data-v-8bc7c3b9=""
                                    >
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/promotion")
                                                }}
                                                role="button"
                                                className="btn -btn-item -promotion-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <img
                                                    alt=" รูปไอคอนโปรโมชั่น"
                                                    className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                    data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                    data-v-8bc7c3b9=""
                                                />
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        โปรโมชั่น
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                href={this.state.linelink}
                                                role="button"
                                                className="btn -btn-item -line-button -menu-center -horizontal"
                                                rel="noopener nofollow"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        ติดต่อ
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        {/**/}
                                        <div className="col-12 -wrapper-box" data-v-8bc7c3b9="">
                                            <div
                                                className="btn -btn-item -line-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <div className="mt-1 mb-1 w-100" data-v-8bc7c3b9="">
                                                    <h3 className="title-footer-wrap pt-2">
                                                        <b>Language / ภาษา </b>
                                                    </h3>
                                                    <ul className="logo-list-footer">
                                                        <li role="button">
                                                            <img
                                                                className="lang-img active-lang"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e1626da-cc1f-451f-838f-54e5e1848f00/public"
                                                                alt=""
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                </div>


                                {/**/}
                            </div>
                        </div>
                    </div>
                    {/**/}
                </div>
                <div className="base-modal-overlay x-modal modal -v2 x-theme-switcher-v2 show animated animatedFadeInUp fadeInUp"
                    data-v-0c0c7fd4=""
                    style={{ display: this.state.modal ? "" : "none" }}
                >
                    <div
                        className="base-modal pt-0 pb-0 w-100"
                        data-v-0c0c7fd4=""
                        style={{ margin: 10, textAlign: "-webkit-center" }}
                    >
                        <div
                            className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable modal-dialog-centered w-100 m-0"
                            data-v-8bc7c3b9=""
                            style={{ height: "auto" }}
                        >
                            <div
                                className="modal-content -modal-content text-white-v1"
                                data-v-8bc7c3b9=""
                            >
                                <a
                                    role="button"
                                    className="close-model-head mt-4 mb-4"
                                    data-v-8bc7c3b9=""
                                    onClick={(e) => {
                                        this.setState({
                                            modal: false
                                        });
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={30}
                                        height={30}
                                        fill="currentColor"
                                        className="bi bi-x-circle-fill"
                                        viewBox="0 0 16 16"
                                        data-v-8bc7c3b9=""
                                    >
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                                            data-v-8bc7c3b9=""
                                        />
                                    </svg>
                                </a>
                                {/**/}
                                <div
                                    className="pt-3 pb-4 x-theme-switcher-v2 text-center mt-3"
                                    data-v-8bc7c3b9=""
                                >
                                    <div className="-inner-header-section" data-v-8bc7c3b9="">
                                        <img
                                            src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                                            alt=""
                                            data-v-8bc7c3b9=""
                                            style={{ width: 160 }}
                                        />
                                        <div className="mt-1 mb-1 w-100" data-v-8bc7c3b9="" style={{ marginTop: "-30px" }}>
                                            <h3 className="title-footer-wrap pt-2">
                                                <b>Language / ภาษา </b>
                                            </h3>
                                            <ul className="logo-list-footer">
                                                <li role="button">
                                                    <img
                                                        className="lang-img active-lang"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e1626da-cc1f-451f-838f-54e5e1848f00/public"
                                                        alt=""
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        className="row -inner-center-body-section m-0"
                                        style={{ color: "white" }}
                                        data-v-8bc7c3b9=""
                                    >
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                role="button"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                                onClick={(e) => {
                                                    this.checkurl(e, "/profile")
                                                }}
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3066ed62-07f3-4623-b4c0-ac1d1eb7e300/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3066ed62-07f3-4623-b4c0-ac1d1eb7e300/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3066ed62-07f3-4623-b4c0-ac1d1eb7e300/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3066ed62-07f3-4623-b4c0-ac1d1eb7e300/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3066ed62-07f3-4623-b4c0-ac1d1eb7e300/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/3066ed62-07f3-4623-b4c0-ac1d1eb7e300/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        <span data-v-8bc7c3b9="">ข้อมูลบัญชี</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/loss")
                                                }}
                                                role="button"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5ab912d5-9d59-4e39-f3fa-f108d3d62500/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5ab912d5-9d59-4e39-f3fa-f108d3d62500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5ab912d5-9d59-4e39-f3fa-f108d3d62500/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5ab912d5-9d59-4e39-f3fa-f108d3d62500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5ab912d5-9d59-4e39-f3fa-f108d3d62500/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5ab912d5-9d59-4e39-f3fa-f108d3d62500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        <span data-v-8bc7c3b9="">คืนยอดเสีย</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/refer/overview")
                                                }}
                                                role="button"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8497de75-3140-4e19-9747-a03adb7c7700/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8497de75-3140-4e19-9747-a03adb7c7700/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8497de75-3140-4e19-9747-a03adb7c7700/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8497de75-3140-4e19-9747-a03adb7c7700/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8497de75-3140-4e19-9747-a03adb7c7700/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8497de75-3140-4e19-9747-a03adb7c7700/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        <span data-v-8bc7c3b9="">เเนะนำเพื่อน</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/statement")
                                                }}
                                                role="button"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a2075ee6-ea48-47ed-726b-01a8b6ec8000/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a2075ee6-ea48-47ed-726b-01a8b6ec8000/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a2075ee6-ea48-47ed-726b-01a8b6ec8000/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a2075ee6-ea48-47ed-726b-01a8b6ec8000/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a2075ee6-ea48-47ed-726b-01a8b6ec8000/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a2075ee6-ea48-47ed-726b-01a8b6ec8000/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        <span data-v-8bc7c3b9="">ประวัติ</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/promotion")
                                                }}
                                                role="button"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        <span data-v-8bc7c3b9="">โปรโมชั่น</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        {/* <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                      <a
                       href='/promotion'
                        role="button"
                        className="btn -btn-item -event-button -menu-center -horizontal"
                        data-v-8bc7c3b9=""
                      >
                        <picture data-v-8bc7c3b9="">
                          <source
                            type="image/webp"
                            data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6311559f-81fe-484a-ea3e-30b9d40fb700/public"
                            srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6311559f-81fe-484a-ea3e-30b9d40fb700/public"
                            data-v-8bc7c3b9=""
                          />
                          <source
                            type="image/png?v=1"
                            data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6311559f-81fe-484a-ea3e-30b9d40fb700/public"
                            srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6311559f-81fe-484a-ea3e-30b9d40fb700/public"
                            data-v-8bc7c3b9=""
                          />
                          <img
                            className="img-fluid -icon-image ls-is-cached lazyloaded"
                            data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6311559f-81fe-484a-ea3e-30b9d40fb700/public"
                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6311559f-81fe-484a-ea3e-30b9d40fb700/public"
                            data-v-8bc7c3b9=""
                          />
                        </picture>
                        <div className="-typo-wrapper" data-v-8bc7c3b9="">
                          <div className="-typo -is-logged" data-v-8bc7c3b9="">
                            <span data-v-8bc7c3b9="">เเลกของรางวัล</span>
                          </div>
                        </div>
                      </a>
                    </div> */}
                                        {/**/}
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                href="/logout"
                                                role="button"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/630a8cf7-f7a8-4ff3-0e58-0b9929ce5500/public"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/630a8cf7-f7a8-4ff3-0e58-0b9929ce5500/public
                "
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/630a8cf7-f7a8-4ff3-0e58-0b9929ce5500/public"
                                                        srcSet="
                  https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/630a8cf7-f7a8-4ff3-0e58-0b9929ce5500/public
                "
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/630a8cf7-f7a8-4ff3-0e58-0b9929ce5500/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/630a8cf7-f7a8-4ff3-0e58-0b9929ce5500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" data-v-8bc7c3b9="">
                                                        ออกจากระบบ
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/**/}
                </div>
                <div className="base-modal-overlay x-modal modal -v2 x-theme-switcher-v2 show animated animatedFadeInUp fadeInUp"
                    data-v-0c0c7fd4=""
                    style={{ display: this.state.modalx ? "" : "none" }}
                >
                    <div
                        className="base-modal pt-0 pb-0 w-100"
                        data-v-8bc7c3b9=""
                        style={{ margin: 10, textAlign: "-webkit-center" }}
                    >
                        <div
                            className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable modal-dialog-centered w-100 m-0"
                            data-v-8bc7c3b9=""
                            style={{ height: "auto" }}
                        >


                            <div
                                className="modal-content -modal-content text-white-v1"
                                data-v-8bc7c3b9=""
                            >
                                <a
                                    onClick={(e) => {
                                        this.setState({
                                            modalx: false
                                        });
                                    }}
                                    role="button"
                                    className="close-model-head mt-4 mb-4"
                                    data-v-8bc7c3b9=""
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={30}
                                        height={30}
                                        fill="currentColor"
                                        className="bi bi-x-circle-fill"
                                        viewBox="0 0 16 16"
                                        data-v-8bc7c3b9=""
                                    >
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                                            data-v-8bc7c3b9=""
                                        />
                                    </svg>
                                </a>


                                <div className="pt-3 pb-4 x-theme-switcher-v2 text-center mt-3"
                                    data-v-8bc7c3b9=""
                                >
                                    <div className="-inner-header-section" data-v-8bc7c3b9="">
                                        <img
                                            src="/build/web/igame-index-lobby-wm/img/logo.png?v=1"
                                            alt=""
                                            data-v-8bc7c3b9=""
                                            style={{ width: 160 }}
                                        />
                                    </div>
                                    {(loginstate != null) ? (!loginstate) ?
                                        <div className="row -inner-top-body-section" data-v-8bc7c3b9="">
                                            <div
                                                className="col-6 -wrapper-box"
                                                data-v-8bc7c3b9=""
                                                style={{ paddingLeft: 0 }}
                                            >
                                                <a
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/register")
                                                    }}
                                                    type="button"
                                                    className="btn -btn-item x-transaction-button-v2 -deposit -top-btn -horizontal"
                                                    data-v-8bc7c3b9=""
                                                >
                                                    <img
                                                        alt="img webรูปไอคอนฝากเงิน"
                                                        className="img-fluid -icon-image"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/279056cb-32d6-4c6d-7291-44366ffa1500/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                        <div className="-title" data-v-8bc7c3b9="">
                                                            สมัครสมาชิก
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div
                                                className="col-6 -wrapper-box"
                                                data-v-8bc7c3b9=""
                                                style={{ paddingRight: 0 }}
                                            >
                                                <a
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/signin")
                                                    }}
                                                    type="button"
                                                    className="btn -btn-item x-transaction-button-v2 -withdraw -top-btn -horizontal"

                                                    data-v-8bc7c3b9=""
                                                >
                                                    <img
                                                        alt=" รูปไอคอนถอนเงิน"
                                                        className="img-fluid -icon-image"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/363edcc3-13dc-460b-671d-6a37e3191e00/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                        <div className="-title" data-v-8bc7c3b9="">
                                                            เข้าสู่ระบบ
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> :
                                        <div className="row -inner-top-body-section" data-v-8bc7c3b9="">
                                            <div
                                                className="col-6 -wrapper-box"
                                                data-v-8bc7c3b9=""
                                                style={{ paddingLeft: 0 }}
                                            >
                                                <a
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/deposit")
                                                    }}
                                                    role="button"
                                                    className="btn -btn-item x-transaction-button-v2 -deposit -top-btn -horizontal"
                                                    data-v-8bc7c3b9=""
                                                >
                                                    <img
                                                        className="img-fluid -icon-image"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d2a5801c-d49e-4e57-ee32-bcb42682e400/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                        <div className="-title" data-v-8bc7c3b9="">
                                                            ฝากเงิน
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div
                                                className="col-6 -wrapper-box"
                                                data-v-8bc7c3b9=""
                                                style={{ paddingRight: 0 }}
                                            >
                                                <a
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/withdraw")
                                                    }}
                                                    type="button"
                                                    className="btn -btn-item x-transaction-button-v2 -withdraw -top-btn -horizontal"
                                                    data-v-8bc7c3b9=""
                                                >
                                                    <img
                                                        className="img-fluid -icon-image"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6829da4a-a4a5-4f8e-2ce0-286825809600/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                        <div className="-title" data-v-8bc7c3b9="">
                                                            ถอนเงิน
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> : ""}
                                    <div
                                        className="row -inner-center-body-section m-0"
                                        data-v-8bc7c3b9=""
                                    >
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/promotion")
                                                }}
                                                role="button"
                                                className="btn -btn-item -promotion-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <img
                                                    alt=" รูปไอคอนโปรโมชั่น"
                                                    className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                    data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                    data-v-8bc7c3b9=""
                                                />
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" style={{ color: "white" }} data-v-8bc7c3b9="">
                                                        โปรโมชั่น
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box" data-v-8bc7c3b9="">
                                            <a
                                                href={this.state.linelink}
                                                role="button"
                                                className="btn -btn-item -line-button -menu-center -horizontal"
                                                rel="noopener nofollow"
                                                data-v-8bc7c3b9=""
                                            >
                                                <picture data-v-8bc7c3b9="">
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                                        srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                    <img
                                                        className="img-fluid -icon-image ls-is-cached lazyloaded"
                                                        data-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a5205298-bde9-4817-4f93-32dc3822b700/public"
                                                        data-v-8bc7c3b9=""
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper" data-v-8bc7c3b9="">
                                                    <div className="-typo -is-logged" style={{ color: "white" }} data-v-8bc7c3b9="">
                                                        ติดต่อ
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        {/**/}
                                        <div className="col-12 -wrapper-box" data-v-8bc7c3b9="">
                                            <div
                                                className="btn -btn-item -line-button -menu-center -horizontal"
                                                data-v-8bc7c3b9=""
                                            >
                                                <div className="mt-1 mb-1 w-100" data-v-8bc7c3b9="">
                                                    <h3 className="title-footer-wrap pt-2">
                                                        <b>Language / ภาษา </b>
                                                    </h3>
                                                    <ul className="logo-list-footer">
                                                        <li role="button">
                                                            <img
                                                                className="lang-img active-lang"
                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e1626da-cc1f-451f-838f-54e5e1848f00/public"
                                                                alt=""
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                    <br data-v-8bc7c3b9="" />
                                </div>


                                {/**/}
                            </div>
                        </div>
                    </div>
                    {/**/}
                </div>
            </>
        );
    }
}

export default Main;
